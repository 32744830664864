import getGroupBy from './getGroupBy';
import Setup from 'components/data/Setup';
import GroupMapping from '../models/groupMapping';

const getInitialCampaignListStructure = () => {
    const setup = Setup.get();

    let placeholderCampaignsPath;
    const groupBy = getGroupBy();

    // Check if there is a placeholder campaign available
    try {
        if (setup.campaigns.calendar.placeholderCampaignsPath)
            placeholderCampaignsPath = setup.campaigns.calendar.placeholderCampaignsPath;
    } catch (error) {}

    if (!groupBy) {
        const defaultCategories = [
            { type: 'featured', campaigns: [] },
            { type: 'future', campaigns: [] },
            { type: 'current', campaigns: [] },
            { type: 'past', campaigns: [] },
            { type: 'unused', campaigns: [] }
        ];

        if (placeholderCampaignsPath) defaultCategories.unshift({ type: 'placeholder', campaigns: [] });

        return defaultCategories;
    } else {
        let groups = {};
        const campaignList = [];

        if (groupBy === 'category') {
            groups = setup.campaigns.categories;
        } else {
            groups = setup[GroupMapping[setup.campaigns.calendar.groupBy]];
        }

        if (placeholderCampaignsPath) campaignList.push({ type: 'placeholder', campaigns: [] });

        Object.keys(groups).forEach((group) => {
            campaignList.push({ type: group, campaigns: [] });
        });

        campaignList.push({ type: 'uncategorized', campaigns: [] });

        return campaignList;
    }
};

export default getInitialCampaignListStructure;
