import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import TextField from 'components/ui-components-v2/TextField';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Select from 'components/ui-components-v2/Select';
import Button from 'components/ui-components-v2/Button';
import { DatePicker } from 'components/input/DatePicker';
import { withResources } from 'components/data/Resources';
import getPhaseStages from '../helpers/getPhaseStages';
import '../styles/table.scss';

function CampaignFlightManagerTable({ rows = [], onChange, onDuplicate, onRemove, onNew, dcmConfig }) {
    const phaseStages = getPhaseStages(dcmConfig);

    return (
        <div>
            <Table className="campaign-flight-manager-table" size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell className="campaign-flight-manager-table__goal">Goal</TableCell>
                        <TableCell className="campaign-flight-manager-table__title">Title</TableCell>
                        <TableCell className="campaign-flight-manager-table__online">Date online</TableCell>
                        <TableCell className="campaign-flight-manager-table__offline">Date offline</TableCell>
                        <TableCell className="campaign-flight-manager-table__actions" align="right">
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id} tabIndex={0}>
                            <TableCell className="campaign-flight-manager-table__goal">
                                <Select value={row.goal} fullWidth={true} onChange={(e) => onChange(row.id, 'goal')(e.target.value)}>
                                    {phaseStages.map((phase) => (
                                        <MenuItem key={phase.key} value={phase.key}>
                                            {phase.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell className="campaign-flight-manager-table__title">
                                <TextField
                                    fullWidth={true}
                                    value={row.title}
                                    onChange={(e) => onChange(row.id, 'title')(e.target.value)}
                                    inputProps={{ maxLength: 20 }}
                                />
                            </TableCell>
                            <TableCell className="campaign-flight-manager-table__online">
                                <DatePicker showTimeZone={false} value={row.online} onMutation={onChange(row.id, 'online')} />
                            </TableCell>
                            <TableCell className="campaign-flight-manager-table__offline">
                                <DatePicker showTimeZone={false} value={row.offline} onMutation={onChange(row.id, 'offline')} />
                            </TableCell>
                            <TableCell align="right" className="campaign-flight-manager-table__actions">
                                <IconButton size="small" onClick={onDuplicate(row.id)} tabIndex={-1}>
                                    <FileCopyIcon />
                                </IconButton>
                                <IconButton size="small" onClick={onRemove(row.id)} tabIndex={-1}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className="campaign-flight-manager-table__actions">
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => onNew()}>
                    Add flight
                </Button>
            </div>
        </div>
    );
}

export default withResources(CampaignFlightManagerTable, ['dcmConfig']);
