import React from 'react';
import '../styles/featured-event-dialog.scss';
import { v4 as uuidv4 } from 'uuid';
import FormFlow from 'components/ui-base/FormFlow';
import Request from 'components/data/Request';
import Dialog from 'components/ui-components/Dialog';

class FeaturedEventDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            isEdit: false
        };
    }

    componentDidMount() {
        const { eventData = {} } = this.props;
        const data = { ...eventData };

        if (data.dateOnline && data.dateOffline) {
            data.date = {
                online: data.dateOnline,
                offline: data.dateOffline
            };
            delete data.dateOnline;
            delete data.dateOffline;
        }

        this.setState({
            data,
            isEdit: Object.keys(data).length > 0
        });
    }

    handleSave = (formData) => {
        const { featuredEvents } = this.props;
        const newCalendarEvents = [...featuredEvents];

        const targetEvent = newCalendarEvents.find((e) => e.id == formData.id);

        if (targetEvent) {
            const index = newCalendarEvents.findIndex((e) => e.id == formData.id);

            newCalendarEvents[index] = {
                ...newCalendarEvents[index],
                image: formData.image,
                title: formData.title,
                dateOnline: formData.date ? formData.date.online : '',
                dateOffline: formData.date ? formData.date.offline : ''
            };

            delete newCalendarEvents[index].date;
        } else {
            const newFormData = {
                id: uuidv4(),
                status: 'featured',
                type: 'featured_event',
                image: formData.image,
                title: formData.title,
                dateOnline: formData.date ? formData.date.online : '',
                dateOffline: formData.date ? formData.date.offline : ''
            };

            newCalendarEvents.push(newFormData);
        }

        const data = {
            events: newCalendarEvents
        };

        return Request.post('/resources/saveCalendarEvents', { data: data });
    };

    render() {
        const { data, isEdit } = this.state;
        const { open, onClose } = this.props;

        const setup = [
            {
                items: [
                    {
                        type: 'file',
                        model: 'image',
                        title: 'Upload event image'
                    },
                    {
                        type: 'text',
                        model: 'title',
                        title: 'Featured event name*',
                        validators: 'required'
                    },
                    {
                        type: 'dateRange',
                        model: 'date',
                        title: 'Event date range*',
                        validators: 'required'
                    }
                ]
            }
        ];

        return (
            <div className="featured-event-dialog">
                <Dialog title={`${isEdit ? 'Edit' : 'Add'} featured event`} onClose={onClose} open={open}>
                    <div className="featured-event-dialog__form">
                        <FormFlow
                            onSubmit={this.handleSave}
                            onSubmitComplete={onClose}
                            setup={setup}
                            data={data}
                            submitButtonLabel={`${isEdit ? 'Edit' : 'Add'} featured event`}
                            saveNotification={`Featured event is succesfully ${isEdit ? 'editted' : 'added'}`}
                        />
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default FeaturedEventDialog;
