import React from 'react';
import '../styles/main.scss';
import moment from 'moment';
import sizeMe from 'react-sizeme';
import GridHeader from './grid-header';
import GridRows from './grid-rows';

/**
 * Class CalendarGrid
 * Adds the grid interface of the calendar
 */
class CalendarGrid extends React.Component {
    shouldComponentUpdate(nextProps) {
        const { filteredCampaigns, showChannels, collapsed } = this.props;

        if (
            nextProps.campaigns != filteredCampaigns ||
            nextProps.showChannels.length !== showChannels.length ||
            nextProps.collapsed.length !== collapsed.length
        ) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const {
            currentDate,
            timeframe,
            filteredCampaigns,
            startDate,
            endDate,
            onCampaignClick,
            name,
            size: { width },
            collapsed,
            collapseRows,
            prevTimeRange,
            nextTimeRange,
            toggleShowMore,
            compactView,
            showChannels,
            toggleShowChannels,
            loading,
            featuredEvents,
            featuredEventsDateRanges,
            refreshFeaturedEvents,
            filters
        } = this.props;

        let cellCount;

        // Determine how many cells each row should have
        if (timeframe === 'week') {
            cellCount = Math.round(moment.duration(endDate.diff(startDate)).asDays());
        } else if (timeframe === 'month') {
            cellCount = Math.round(moment.duration(endDate.diff(startDate)).asDays());
        } else if (timeframe === 'quarterly' || timeframe === 'year') {
            cellCount = Math.round(moment.duration(endDate.diff(startDate)).asWeeks());
        }

        return (
            <div className="calendar-grid">
                <div className={`calendar-grid__header ${!compactView ? 'sticky' : ''}`}>
                    <GridHeader
                        currentDate={currentDate}
                        timeframe={timeframe}
                        startDate={startDate}
                        endDate={endDate}
                        componentWidth={width}
                        filteredCampaigns={filteredCampaigns}
                        prevTimeRange={prevTimeRange}
                        nextTimeRange={nextTimeRange}
                        compactView={compactView}
                        showChannels={showChannels}
                        name={name}
                        featuredEventsDateRanges={featuredEventsDateRanges}
                        cellCount={cellCount}></GridHeader>
                </div>
                <div className="calendar-grid__cells">
                    <GridRows
                        currentDate={currentDate}
                        timeframe={timeframe}
                        startDate={startDate}
                        endDate={endDate}
                        compactView={compactView}
                        componentWidth={width}
                        filteredCampaigns={filteredCampaigns}
                        onCampaignClick={onCampaignClick}
                        refreshFeaturedEvents={refreshFeaturedEvents}
                        showChannels={showChannels}
                        toggleShowChannels={toggleShowChannels}
                        loading={loading}
                        toggleShowMore={toggleShowMore}
                        cellCount={cellCount}
                        name={name}
                        collapsed={collapsed}
                        collapseRows={collapseRows}
                        featuredEvents={featuredEvents}
                        filters={filters}></GridRows>
                </div>
            </div>
        );
    }
}

export default sizeMe()(CalendarGrid);
