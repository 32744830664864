import React from 'react';
import '../styles/grid.scss';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import classNames from 'classnames';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Illustration from 'components/ui-components-cape/Illustration';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import User from 'components/data/User';
import Avatar from 'components/ui-components-v2/Avatar';
import Request from 'components/data/Request';
import Typography from 'components/ui-components-v2/Typography';
import Setup from 'components/data/Setup';
import FeaturedEventDialog from './featured-event-dialog';
import getCategoryColor from '../helpers/getCategoryColor';

/**
 * Class CalendarGridRowSubheaderFeatured
 */
class CalendarGridRowSubheaderFeatured extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hoveredSubheader: null,
            showFeaturedEventDialog: false,
            featuredEventData: {},
            showConfirmDialog: false
        };

        this.setup = Setup.get();
    }

    render() {
        const { showFeaturedEventDialog, showConfirmDialog, featuredEventData } = this.state;
        const { featuredEvents } = this.props;

        return (
            <React.Fragment>
                {this.renderSubheader()}
                <div>
                    {showFeaturedEventDialog && (
                        <FeaturedEventDialog
                            open={showFeaturedEventDialog}
                            eventData={featuredEventData}
                            featuredEvents={featuredEvents}
                            onClose={this.closeFeaturedEventDialog}
                        />
                    )}

                    {showConfirmDialog && (
                        <ConfirmDialog
                            open={showConfirmDialog}
                            title={'Remove this featured event?'}
                            description={featuredEventData.title}
                            onConfirm={() => this.deleteFeaturedEvent(featuredEventData)}
                            onClose={() => this.closeConfirmDialog()}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }

    /**
     * Delete featured event from subheader
     * @param {*} event
     */
    deleteFeaturedEvent = async (event) => {
        const { featuredEvents } = this.props;
        const newCalendarEvents = [...featuredEvents];
        const targetEventIndex = newCalendarEvents.findIndex((e) => e.id == event.id);

        if (targetEventIndex > -1) {
            newCalendarEvents.splice(targetEventIndex, 1);
        }

        const data = {
            events: newCalendarEvents
        };
        await Request.post('/resources/saveCalendarEvents', { data: data });

        this.closeConfirmDialog();
    };

    hoverSubheader = (event) => {
        if (event.type === 'featured_event' && User.hasRight('calendarEventManagement')) {
            this.setState({ hoveredSubheader: event.id });
        }
    };

    leaveSubheader = (event) => {
        if (event.type === 'featured_event' && User.hasRight('calendarEventManagement')) {
            this.setState({ hoveredSubheader: null });
        }
    };

    openConfirmDialog = (event) => {
        this.setState({ showConfirmDialog: true, featuredEventData: event });
    };

    closeConfirmDialog = () => {
        this.setState({ showConfirmDialog: false, featuredEventData: {} }, () => {
            this.props.refreshFeaturedEvents();
        });
    };

    openFeaturedEventDialog = (data = {}) => {
        this.setState({ showFeaturedEventDialog: true, featuredEventData: data });
    };

    closeFeaturedEventDialog = () => {
        this.setState({ showFeaturedEventDialog: false, featuredEventData: {} }, () => {
            this.props.refreshFeaturedEvents();
        });
    };

    renderSubheader = () => {
        const { campaign, onCampaignClick, groupIndex } = this.props;
        const { hoveredSubheader } = this.state;

        const updatedStyle = {};

        if (campaign.combinedCampaigns) {
            updatedStyle.color = getCategoryColor(campaign.type, groupIndex);
        }

        if (campaign.type === 'featured_event') {
            return (
                <div
                    className="calendar-grid-rows__row__sidebar__campaign"
                    onClick={() => onCampaignClick(campaign)}
                    onMouseEnter={() => this.hoverSubheader(campaign)}
                    onMouseLeave={() => this.leaveSubheader(campaign)}>
                    <Avatar
                        className={classNames('calendar-grid-rows__row__sidebar__campaign__image', {
                            ['calendar-grid-rows__row__sidebar__campaign__image--no-image']: !campaign.image
                        })}
                        size="large"
                        variant="rounded"
                        src={campaign.image}>
                        <Illustration size="inherit" />
                    </Avatar>
                    <Typography variant="caption" component="div">
                        {campaign.title}
                    </Typography>

                    {hoveredSubheader == campaign.id && (
                        <div className="calendar-grid-rows__row__sidebar__campaign__actions">
                            <Tooltip title="Edit">
                                <IconButton style={{ width: 32, marginRight: 4 }} size="small" onClick={() => this.openFeaturedEventDialog(campaign)}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton style={{ width: 32 }} size="small" onClick={() => this.openConfirmDialog(campaign)}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </div>
            );
        } else if (campaign.type === 'featured') {
            return (
                <div className="calendar-grid-rows__row__sidebar__subheader" data-tour-selector={campaign.type}>
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}>
                        <FiberManualRecordIcon
                            style={{ fontSize: 10, margin: 0 }}
                            className={`calendar-grid-rows__row__sidebar__subheader__icon--${campaign.type}`}
                        />
                        <Typography variant="subtitle2" component="div" className="calendar-grid-rows__row__sidebar__subheader__copy">
                            <span style={{ textTransform: 'capitalize' }}>{campaign.type}</span> events {`(${campaign.totalCampaigns})`}
                        </Typography>
                        {User.hasRight('calendarEventManagement') && (
                            <Tooltip title="Add custom event">
                                <ControlPointIcon onClick={() => this.openFeaturedEventDialog()} color="primary" />
                            </Tooltip>
                        )}
                    </div>
                </div>
            );
        }
    };
}

export default CalendarGridRowSubheaderFeatured;
