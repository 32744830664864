import EditorData from 'components/editor-data/EditorData';

const fetchFlightData = (flight) => {
    return {
        ...flight,
        goal: EditorData.getValueFromModel(`flights.${flight.id}.settings.goal`),
        online: EditorData.getValueFromModel(`flights.${flight.id}.settings.planning.online`),
        offline: EditorData.getValueFromModel(`flights.${flight.id}.settings.planning.offline`)
    };
};

export default fetchFlightData;
