import React from 'react';
import '../styles/grid.scss';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import classNames from 'classnames';
import moment from 'moment/moment.js';
import Typography from 'components/ui-components-v2/Typography';
import Tooltip from 'components/ui-components-v2/Tooltip';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Illustration from 'components/ui-components-cape/Illustration';
import Avatar from 'components/ui-components-v2/Avatar';
import Setup from 'components/data/Setup';
import { withResources } from 'components/data/Resources';
import getCategoryColor from '../helpers/getCategoryColor';
import CalendarGridRowSubheaderFeatured from './grid-rows-subheader-featured';
import GroupMapping from '../models/groupMapping';

/**
 * Class CalendarGridRowSubheader
 */
class CalendarGridRowSubheader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingMore: '',
            hoveredSubheader: null
        };

        this.setup = Setup.get();
    }

    /**
     * Show more campaigns
     * @param {*} campaign
     */
    toggleShowMore = (campaign) => {
        this.setState({ loadingMore: campaign.group }, () => {
            setTimeout(() => {
                this.props.toggleShowMore(campaign);
            }, 500);

            setTimeout(() => {
                this.setState({ loadingMore: '' });
            }, 800);
        });
    };

    /**
     * Get the color of the dot icon
     * @param {*} campaign
     */
    getColorClass = (campaign) => {
        const defaultGroups = ['past', 'current', 'future', 'unused', 'disabled', 'featured', 'placeholder'];

        if (defaultGroups.includes(campaign.type)) {
            return campaign.type;
        }

        const dateOnline = moment(campaign.dateOnline).valueOf();
        const dateOffline = moment(campaign.dateOffline).valueOf();

        if (dateOnline > Date.now()) {
            return 'future';
        } else if (dateOnline < Date.now() && dateOffline > Date.now()) {
            return 'current';
        } else {
            return 'past';
        }
    };

    /**
     * Determine the copy of the subheader in the sidebar (e.g. Future campaigns (12), etc)
     */
    getSubheaderCopy = () => {
        const { campaign, name } = this.props;
        let groupBy, groupByOptions;

        try {
            if (this.setup.campaigns.calendar.groupBy && this.setup[GroupMapping[this.setup.campaigns.calendar.groupBy]]) {
                groupBy = this.setup.campaigns.calendar.groupBy;
                groupByOptions = this.setup[GroupMapping[groupBy]];
            }
        } catch (error) {}

        // Return a different copy if the calendar is grouped by markets
        if (groupBy === 'market') {
            return <span> {`${groupByOptions[campaign.type]} (${campaign.totalCampaigns})`}</span>;
        }

        return (
            <>
                <span style={{ textTransform: 'capitalize' }}>{campaign.type} </span>
                {`${name} (${campaign.totalCampaigns})`}
            </>
        );
    };

    /**
     * This checks if there is a phase stage mapping available in the resources. If so, it takes the label from the resources
     * Example: Philips uses 'See' as a phase, but they want to display it as 'Awareness'
     * @param {String} goal
     * @returns The label of the goal
     */
    mapCampaignGoal = (goal) => {
        const { dcmConfig } = this.props;

        if (dcmConfig && dcmConfig.mapping && dcmConfig.mapping.phaseStage && dcmConfig.mapping.phaseStage[goal.toLowerCase()]) {
            return dcmConfig.mapping.phaseStage[goal.toLowerCase()];
        }

        return goal;
    };

    render() {
        const { campaign, collapsed, name, compactView, onCampaignClick, collapseRows, groupIndex, featuredEvents, refreshFeaturedEvents } = this.props;
        const { loadingMore, hoveredSubheader } = this.state;

        const updatedStyle = {};

        if (campaign.combinedCampaigns) {
            updatedStyle.color = getCategoryColor(campaign.type, groupIndex);
        }

        let image;

        if (campaign.type === 'campaign') {
            if (campaign.image) {
                image = campaign.image;
            } else if (campaign.campaignFormat) {
                image = campaign.campaignFormat.image;
            }
        }

        if (['campaign', 'featured_event', 'featured', 'fill', 'showmore', 'showless'].includes(campaign.type)) {
            return (
                <>
                    {campaign.type === 'campaign' && (
                        <div className="calendar-grid-rows__row__sidebar__campaign" onClick={() => onCampaignClick(campaign)}>
                            <Avatar
                                className={classNames('calendar-grid-rows__row__sidebar__campaign__image', {
                                    ['calendar-grid-rows__row__sidebar__campaign__image--no-image']: !image
                                })}
                                size="large"
                                variant="rounded"
                                src={image}>
                                <Illustration size="inherit" />
                            </Avatar>
                            <Typography variant="caption" component="div">
                                {campaign.title}
                            </Typography>

                            {hoveredSubheader == campaign.id && (
                                <div className="calendar-grid-rows__row__sidebar__campaign__actions">
                                    <Tooltip title="Edit">
                                        <IconButton style={{ width: 32, marginRight: 4 }} size="small" onClick={() => this.openFeaturedEventDialog(campaign)}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton style={{ width: 32 }} size="small" onClick={() => this.openConfirmDialog(campaign)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}

                    {(campaign.type === 'featured_event' || campaign.type === 'featured') && (
                        <CalendarGridRowSubheaderFeatured
                            campaign={campaign}
                            onCampaignClick={onCampaignClick}
                            groupIndex={groupIndex}
                            featuredEvents={featuredEvents}
                            refreshFeaturedEvents={refreshFeaturedEvents}
                        />
                    )}

                    {campaign.type === 'fill' && <div className="calendar-grid-rows__row__sidebar__campaign"></div>}

                    {(campaign.type === 'showmore' || campaign.type === 'showless') && (
                        <div className="calendar-grid-rows__row__sidebar__show-more" onClick={() => this.toggleShowMore(campaign)}>
                            <span>
                                <span style={{ marginRight: 16 }}>{campaign.type === 'showmore' ? '+' : '-'}</span> View{' '}
                                {campaign.type === 'showmore' ? ' more' : ' less'} {name}
                            </span>
                            {loadingMore === campaign.group && <CircularProgress className="calendar-grid-rows__row__sidebar__spinner" />}
                        </div>
                    )}
                </>
            );
        } else {
            return (
                <div className="calendar-grid-rows__row__sidebar__subheader" data-tour-selector={campaign.type}>
                    {campaign.type === 'channel' && (
                        <div style={{ display: 'flex' }} onClick={() => this.onCampaignClick(campaign)}>
                            <div className="calendar-grid-rows__row__sidebar__subheader__arrow"></div>
                            <span style={{ fontSize: 12 }}>Channel: {campaign.channel}</span>
                        </div>
                    )}
                    {campaign.goal && (
                        <Typography variant="subline2" component="span">
                            <span style={{ textTransform: 'capitalize', color: '#6d6d6d' }}>Goal: </span>
                            {this.mapCampaignGoal(campaign.goal)}
                        </Typography>
                    )}
                    {campaign.type !== 'channel' && campaign.type !== 'featured' && !campaign.goal && (
                        <div onClick={() => collapseRows(campaign.type)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}>
                            <div>
                                <FiberManualRecordIcon
                                    style={{ fontSize: 10, margin: 0, ...updatedStyle }}
                                    className={`calendar-grid-rows__row__sidebar__subheader__icon--${this.getColorClass(campaign)}`}
                                />
                            </div>
                            <Typography variant="subtitle2" component="div" className="calendar-grid-rows__row__sidebar__subheader__copy">
                                {this.getSubheaderCopy()}
                            </Typography>

                            {!compactView && (
                                <div>
                                    {collapsed.includes(campaign.type) ? (
                                        <KeyboardArrowDownIcon className="calendar-grid-rows__row__sidebar__subheader__collapse-icon" />
                                    ) : (
                                        <KeyboardArrowUpIcon className="calendar-grid-rows__row__sidebar__subheader__collapse-icon" />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            );
        }
    }
}

export default withResources(CalendarGridRowSubheader, ['dcmConfig']);
