import getGroupBy from './getGroupBy';
import Setup from 'components/data/Setup';
import GroupMapping from '../models/groupMapping';

const getInitialShowCount = () => {
    const setup = Setup.get();
    const groupBy = getGroupBy();

    let placeholderCampaignsPath = null;

    // Check if there is a placeholder campaign available
    try {
        if (setup.campaigns.calendar.placeholderCampaignsPath)
            placeholderCampaignsPath = setup.campaigns.calendar.placeholderCampaignsPath;
    } catch (error) {}

    if (!groupBy) {
        // Return the default
        return {
            ...(placeholderCampaignsPath && { placeholder: { campaignsToShow: 20, totalCampaigns: null } }),
            featured: { campaignsToShow: 20, totalCampaigns: null },
            future: { campaignsToShow: 20, totalCampaigns: null },
            current: { campaignsToShow: 20, totalCampaigns: null },
            past: { campaignsToShow: 20, totalCampaigns: null },
            unused: { campaignsToShow: 20, totalCampaigns: null }
        };
    } else {
        let groupOptions = {};
        const showCount = {};

        if (groupBy === 'category') {
            groupOptions = setup.campaigns.categories;
        } else {
            groupOptions = setup[GroupMapping[setup.campaigns.calendar.groupBy]];
        }

        if (placeholderCampaignsPath) {
            showCount['placeholder'] = { campaignsToShow: 20, totalCampaigns: null };
        }

        Object.keys(groupOptions).forEach((category) => {
            showCount[category] = { campaignsToShow: 20, totalCampaigns: null };
        });

        showCount['uncategorized'] = { campaignsToShow: 20, totalCampaigns: null };

        return showCount;
    }
};

export default getInitialShowCount;
