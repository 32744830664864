import moment from 'moment';

/**
 * Calculates and returns the startDate and endDate of the current calendar view
 */
const getStartEndDate = (_currentDate, _timeframe) => {
    const currentDate = moment(_currentDate ? _currentDate : Date.now()).valueOf();
    const timeframe = _timeframe ? _timeframe : 'month';
    let startDate, endDate;

    if (timeframe === 'week') {
        startDate = moment(currentDate).startOf('isoWeek').subtract(1, 'days');
        endDate = moment(currentDate).endOf('isoWeek').add(1, 'days');
    } else if (timeframe === 'month') {
        startDate = moment(currentDate).startOf('month').subtract(3, 'days');
        endDate = moment(currentDate).endOf('month').add(3, 'days');
    } else if (timeframe === 'quarterly') {
        startDate = moment(currentDate).startOf('quarter').subtract(1, 'weeks');
        endDate = moment(currentDate).endOf('quarter').add(1, 'weeks');
    } else if (timeframe === 'year') {
        const monthNumber = moment(currentDate).month() + 1;
        const year = moment(currentDate).year();
        if (monthNumber < 7) {
            startDate = moment(currentDate).startOf('year').subtract(2, 'weeks');
            endDate = moment(currentDate).startOf('year').add(6, 'months').add(2, 'weeks');
        } else {
            startDate = moment(`${year}-07-01`).subtract(2, 'weeks');
            endDate = moment(startDate).add(6, 'months').add(4, 'weeks');
        }
    }

    return { startDate, endDate };
};

export default getStartEndDate;
