import Setup from 'components/data/Setup';

const getCategoryColor = (category, groupIndex) => {
    const setup = Setup.get();

    // Take the default color
    if (category === 'past' || category === 'current' || category === 'future') return;

    let categoryColors;

    try {
        if (setup.campaigns.calendar.categoryColors && setup.campaigns.categories) {
            categoryColors = setup.campaigns.calendar.categoryColors;
        }
    } catch (error) {}

    const defaultCategoryColors = {
        placeholder: '#fff',
        awareness: '#374045',
        consideration: '#ffa099',
        sales: '#b468ac',
        loyalty: '#ffad6a',
        uncategorized: '#999999'
    };

    const colorList = ['#ff9b95', '#e16bce', '#788cff', '#00f6ce', '#ff80f1', '#ffa95b'];

    if (categoryColors && categoryColors[category]) {
        return categoryColors[category];
    } else if (defaultCategoryColors[category]) {
        return defaultCategoryColors[category];
    } else if (typeof groupIndex === 'number') {
        return colorList[groupIndex % 6];
    }
};

export default getCategoryColor;
