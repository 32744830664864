import React from 'react';
import '../styles/grid.scss';
import GridHeaderSidebar from './grid-header-sidebar';
import GridHeaderDates from './grid-header-dates';

/**
 * Class CalendarGridHeader
 * Adds the grid interface of the calendar
 */
class CalendarGridHeader extends React.Component {
    render() {
        const {
            currentDate,
            filteredCampaigns,
            timeframe,
            startDate,
            endDate,
            cellCount,
            componentWidth,
            name,
            compactView,
            showChannels,
            prevTimeRange,
            nextTimeRange
        } = this.props;

        return (
            <div className="calendar-grid-header">
                <GridHeaderSidebar
                    currentDate={currentDate}
                    filteredCampaigns={filteredCampaigns}
                    name={name}
                    compactView={compactView}
                    prevTimeRange={prevTimeRange}
                    nextTimeRange={nextTimeRange}
                />
                <GridHeaderDates
                    filteredCampaigns={filteredCampaigns}
                    timeframe={timeframe}
                    startDate={startDate}
                    endDate={endDate}
                    cellCount={cellCount}
                    componentWidth={componentWidth}
                    compactView={compactView}
                    showChannels={showChannels}
                    prevTimeRange={prevTimeRange}
                    nextTimeRange={nextTimeRange}
                />
            </div>
        );
    }
}

export default CalendarGridHeader;
