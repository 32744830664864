import Setup from 'components/data/Setup';
import GroupMapping from '../models/groupMapping';

/**
 * Check if the calendar should be grouped by a specific value (e.g. 'market', 'department')
 * The categoryGrouping is option is there to be backwards compatible (when only grouping by category was possible)
 */
const getGroupBy = () => {
    const setup = Setup.get();

    let groupBy;

    try {
        if (
            (setup.campaigns.calendar.groupBy &&
                (setup[GroupMapping[setup.campaigns.calendar.groupBy]] || setup.campaigns.categories)) ||
            (setup.campaigns.calendar.categoryGrouping && setup.campaigns.categories)
        ) {
            groupBy = setup.campaigns.calendar.groupBy ? setup.campaigns.calendar.groupBy : 'category';
        }
    } catch (error) {}

    return groupBy;
};

export default getGroupBy;
