import moment from 'moment';

/**
 * Calculates the width of the cells of the top 3 headers for this specific timeframe (e.g. December 2021, week 49, dec 7th)
 * @param {*} startDate Startdate of the active timeframe
 * @param {*} endDate Enddate of the active timeframe
 * @param {*} cellWidthPerc The width in % for each cell
 */
const getCellPercentages = (startDate, endDate, cellWidthPerc, currentDate, timeframe) => {
    // The three rows in the header (month/week/day for smaller timeframes | year/month/week for bigger timeframes)
    // These are arrays of numbers, where the number specifies the width of a cell as a percentage
    const topRowCellWidths = [],
        middleRowCellWidths = [],
        bottomRowCellWidths = [];

    if (timeframe === 'week' || timeframe === 'month') {
        const daysInMonth = moment(currentDate).daysInMonth();

        // Calculate the width of the first cell in the top row and push it to the array
        let topFirstCellWidth = Math.round(moment.duration(moment(startDate).endOf('month').diff(startDate)).asDays()) * cellWidthPerc;
        if (topFirstCellWidth > 100) topFirstCellWidth = 100;
        topRowCellWidths.push(topFirstCellWidth);

        // Calculate and push the other cells
        if (timeframe === 'week') {
            topRowCellWidths.push(100 - topFirstCellWidth);
        } else {
            // Push the full month
            topRowCellWidths.push(daysInMonth * cellWidthPerc);

            // Push the remaining last cell (if needed)
            topRowCellWidths.push(100 - topFirstCellWidth - daysInMonth * cellWidthPerc);
        }

        // Calculate the width of the first week cell and push it to the array
        const middleFirstCellWidth = Math.round(moment.duration(moment(startDate).endOf('isoWeek').diff(startDate)).asDays()) * cellWidthPerc;
        middleRowCellWidths.push(middleFirstCellWidth);

        // Calculate how many weeks fit into the remaining space and push those to the array
        const fullWeeksLeft = Math.floor((100 - middleFirstCellWidth) / (7 * cellWidthPerc));
        for (let i = 0; i < fullWeeksLeft; i++) {
            middleRowCellWidths.push(7 * cellWidthPerc);
        }

        // Push the last cell
        middleRowCellWidths.push(100 - middleFirstCellWidth - fullWeeksLeft * (7 * cellWidthPerc));
    }

    // If the timeframe is "quarterly" or "yearly"
    else {
        // Calculate the width of the first cell of the top row
        let topFirstCellWidth = Math.round(moment.duration(moment(startDate).endOf('year').diff(startDate)).asWeeks()) * cellWidthPerc;
        if (topFirstCellWidth >= 100) topFirstCellWidth = 100;

        // Push the first cell and if the first cell is not 100%, fill it up with a second cell
        topRowCellWidths.push(topFirstCellWidth, 100 - topFirstCellWidth);

        // Calculate the width of the first cell of the middle row and push it to the array
        const middleFirstCellWidth = Math.round(moment.duration(moment(startDate).endOf('month').diff(startDate)).asWeeks()) * cellWidthPerc;
        middleRowCellWidths.push(middleFirstCellWidth);

        const daysInTimeframe = Math.round(moment.duration(moment(endDate).diff(startDate)).asDays());
        const monthCount = timeframe === 'quarterly' ? 3 : 6;

        // For each month, calculate the amount of days (we use i + 1, because the startDate is two weeks before the first month) and push each month to the array
        for (let i = 0; i < monthCount; i++) {
            const daysInMonth = moment(startDate)
                .add(i + 1, 'months')
                .daysInMonth();
            middleRowCellWidths.push((daysInMonth / daysInTimeframe) * 100);
        }

        // Calculate the width of the first cell of the bottom row and push it to the array
        const bottomFirstCellWidth = (Math.round(moment.duration(moment(startDate).endOf('isoWeek').diff(startDate)).asDays()) / 7) * cellWidthPerc;
        bottomRowCellWidths.push(bottomFirstCellWidth);

        // Calculate how many weeks fit into the remaining space and push those to the array
        const fullWeeksLeft = Math.round((100 - bottomFirstCellWidth) / cellWidthPerc);

        // Push each full week to the array
        for (let i = 0; i < fullWeeksLeft; i++) {
            bottomRowCellWidths.push(cellWidthPerc);
        }

        // Push the last cell
        bottomRowCellWidths.push(100 - bottomFirstCellWidth - fullWeeksLeft * cellWidthPerc);
    }

    return { topRowCellWidths, middleRowCellWidths, bottomRowCellWidths };
};

export default getCellPercentages;
