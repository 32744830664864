import moment from 'moment';
import addCampaignsToCampaignList from './addCampaignsToCampaignList';

/**
 *  Get the campaigns in the view and download them as a xlsx file
 * @param {*} campaigns The list of all campaigns
 * @param {*} filters The active filters
 * @param {*} startDate The current start date
 * @param {*} endDate The current end date
 */
const exportToExcel = (campaigns, filters, startDate, endDate) => {
    const campaignsToExport = [];

    const campaignListByType = addCampaignsToCampaignList(campaigns, startDate, endDate, filters);

    campaignListByType.forEach((campaignType) => {
        if (!campaignType.campaigns) return;

        campaignType.campaigns.forEach((campaign) => {
            if (campaign.type === 'campaign') {
                campaignsToExport.push({
                    title: campaign.title,
                    market: campaign.market,
                    description: campaign.description,
                    startDate: campaign.dateOnline,
                    endDate: campaign.dateOffline,
                    category: campaign.category,
                    tags: campaign.tags,
                    channels: campaign.channels
                });
            }
        });
    });

    // Download the xlsx file
    fetch(process.env.APP_API_HOST + 'feeds/exportCalendar', {
        method: 'POST',
        body: JSON.stringify({ data: campaignsToExport }),
        responseType: 'blob',
        credentials: 'omit'
    })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `export_calendar_${moment(startDate).format('DD-MM-YYYY')}_${moment(endDate).format('DD-MM-YYYY')}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
};

export default exportToExcel;
