import React from 'react';
import moment from 'moment';

const GridHeaderDatesMiddle = ({ cells, timeframe, startDate }) => {
    return cells.map((cellWidth, i) => (
        <div
            className={`calendar-grid-header__dates__middle__cell ${timeframe === 'week' || timeframe === 'quarterly' ? 'is-active-timeframe' : ''}`}
            style={{ width: `${cellWidth}%` }}
            key={i}>
            {(timeframe === 'week' || timeframe === 'month') && <span>Week {moment(startDate).add(i, 'weeks').isoWeek()}</span>}
            {(timeframe === 'quarterly' || timeframe === 'year') && moment(startDate).add(i, 'months').format('MMMM')}
        </div>
    ));
};

export default GridHeaderDatesMiddle;
