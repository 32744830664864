import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEqual } from 'lodash';
import { withRouter } from 'react-router-dom';
import html2canvas from 'html2canvas';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Button from 'components/ui-components-v2/Button';
import { withResources } from 'components/data/Resources';
import { GenericFilterChips } from 'components/ui-base/GenericFilter';

import User from 'components/data/User';
import Request from 'components/data/Request';
import Setup from 'components/data/Setup';
import DataHelpers from 'components/data/DataHelpers';
import Grid from './grid';
import CalendarHeader from './header';
import getInitialShowCount from '../helpers/getInitialShowCount';
import getStartEndDate from '../helpers/getStartEndDate';
import addCampaignsToCampaignList from '../helpers/addCampaignsToCampaignList';
import exportToExcel from '../helpers/exportToExcel';
import '../styles/main.scss';

/**
 * Class Calendar
 * Displays the calendar with all the campaigns in there
 */
class Calendar extends React.Component {
    constructor(props) {
        super(props);

        this.setup = Setup.get();

        const timeframe = this.getTimeframe();
        const { startDate, endDate } = getStartEndDate(props.currentDate, timeframe);

        this.state = {
            currentDate: props.currentDate,
            timeframe,
            name: props.name,
            filteredCampaigns: [],
            showChannels: [],
            loading: props.loading,
            collapsed: [],
            featuredEvents: this.getFeaturedEvents(),
            showCount: getInitialShowCount(),
            startDate,
            endDate,
            collapsedIsModified: false // There is the option to start with everything collapsed. If the user manually expands a row or shows more campaigns, this makes sure the groups don't collapse everything again
        };
    }

    static propTypes = {
        events: PropTypes.array,
        onEventClick: PropTypes.func,
        onChangeCalendarDateRange: PropTypes.func,
        compactView: PropTypes.bool,
        currentDate: PropTypes.any,
        name: PropTypes.string
    };

    static defaultProps = {
        events: [],
        onEventClick: () => {},
        onChangeCalendarDateRange: () => {},
        compactView: false,
        currentDate: Date.now(),
        name: 'campaigns',
        campaignsFetched: false
    };

    componentDidMount() {
        const { events } = this.props;

        if (events && events.length > 0) {
            this.updateData(events);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { events, loading } = this.props;

        if (prevProps.loading !== loading) {
            this.setState({ loading: loading });
        }

        if (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
            const formattedStartDate = moment(this.state.startDate).format('YYYY-MM-DD');
            const formattedEndDate = moment(this.state.endDate).format('YYYY-MM-DD');
            this.props.onChangeCalendarDateRange(formattedStartDate, formattedEndDate);
        }

        if (
            this.state.timeframe !== prevState.timeframe ||
            this.state.currentDate !== prevState.currentDate ||
            !isEqual(
                events.map((e) => e.id),
                prevProps.events.map((e) => e.id)
            )
        ) {
            this.updateData(events);
        }
    }

    /**
     * Get the featured events, if there are any
     */
    getFeaturedEvents = () => {
        const { calendarEvents, calendar_events } = this.props;
        let featuredEvents = [];

        if (calendarEvents && calendarEvents.events) {
            featuredEvents = calendarEvents.events;
        } else if (calendar_events && calendar_events.events) {
            featuredEvents = calendar_events.events;
        }

        return featuredEvents;
    };

    /**
     * Returns the timeframe that has to be used
     */
    getTimeframe = () => {
        let timeframe = 'month';

        // Check if a default timeframe is giving in the setup
        try {
            if (this.setup.campaigns.calendar.defaultTimeframe) timeframe = this.setup.campaigns.calendar.defaultTimeframe;
        } catch (error) {}

        return timeframe;
    };

    /**
     * Update the state data of the calendar (e.g. initial load, after a change of timeframe, etc)
     */
    updateData = (campaigns) => {
        const { currentDate, timeframe, collapsed } = this.state;

        const { startDate, endDate } = getStartEndDate(currentDate, timeframe);
        const filteredCampaigns = this.getFilteredCampaigns(campaigns, startDate, endDate);
        const updatedShowCount = this.getUpdatedShowCount(filteredCampaigns);
        const updatedCollapsed = this.getUpdatedCollapsed(filteredCampaigns);

        this.setState({
            filteredCampaigns,
            startDate,
            endDate,
            showCount: updatedShowCount,
            collapsed: updatedCollapsed ? updatedCollapsed : collapsed
        });
    };

    /**
     * Get an array of all campaigns and transforms it to a list of campaigns seperated by groups. It also takes the compact view
     * into account (used on the dashboard) and sorts the campaigns by date
     * @param {*} campaigns An array of all raw campaigns
     * @param {*} startDate The start date of the current timeframe
     * @param {*} endDate The end date of the current timeframe
     * @returns
     */
    getFilteredCampaigns = (campaigns, startDate, endDate) => {
        const { compactView, loading, filters = {} } = this.props;
        const { showCount, featuredEvents } = this.state;

        let campaignsAndEvents = loading ? [...campaigns] : [...featuredEvents, ...campaigns];

        if (!campaignsAndEvents || (campaignsAndEvents && campaignsAndEvents.length) === 0) {
            campaignsAndEvents = [];
        }

        let campaignList = addCampaignsToCampaignList(campaignsAndEvents, startDate, endDate, filters);
        let compactCampaignList;

        if (compactView) {
            // This will be used for the list on the dashboard (the compact view of the calendar)
            compactCampaignList = DataHelpers.clone(campaignList);
            let campaignsCount = 0;

            if (campaignList && Array.isArray(campaignList)) {
                campaignList.forEach((campaignType, index) => {
                    const totalCampaigns = campaignType.campaigns.length;
                    const campaignsLeft = 10 - campaignsCount;

                    if (campaignsLeft > 0) {
                        const slicedCampaigns = campaignType.campaigns.slice(0, campaignsLeft);
                        compactCampaignList[index].campaigns = slicedCampaigns;
                        compactCampaignList[index].totalCampaigns = totalCampaigns;
                        campaignsCount += slicedCampaigns.length;
                    }

                    return false;
                });
            }

            // Remove if no campaigns are pushed
            compactCampaignList = compactCampaignList.filter(
                (campaignType) => campaignType.campaigns && campaignType.campaigns.length > 0 && campaignType.type !== 'featured'
            );
        }

        // Remove if no campaigns are pushed
        campaignList = campaignList.filter((campaignType) => campaignType.type === 'featured' || (campaignType.campaigns && campaignType.campaigns.length > 0));

        // Remove entire featured row if there are no featured events and the user doesn't have the rights to add one
        const hasRights = User.hasRight('calendarEventManagement');
        if (!hasRights) {
            campaignList = campaignList.filter(
                (campaignType) => !(campaignType.type === 'featured' && campaignType.campaigns && campaignType.campaigns.length === 0)
            );
        }

        let filteredCampaigns = compactView ? compactCampaignList : campaignList;

        // Sort the campaigns on dateOnline and slice for the show count
        filteredCampaigns = filteredCampaigns.map((campaignType) => {
            const campaignsToShow = showCount[campaignType.type].campaignsToShow;
            const totalCampaigns = campaignType.campaigns.length;
            let sortedCampaigns;

            if (campaignType.type === 'current') {
                sortedCampaigns = campaignType.campaigns.sort((a, b) => moment(b.dateOnline).valueOf() - moment(a.dateOnline).valueOf());
            } else if (campaignType.type === 'past') {
                sortedCampaigns = campaignType.campaigns.sort((a, b) => moment(b.dateOffline).valueOf() - moment(a.dateOffline).valueOf());
            } else {
                sortedCampaigns = campaignType.campaigns.sort((a, b) => moment(a.dateOnline).valueOf() - moment(b.dateOnline).valueOf());
            }

            // Slice for the campaigns to show count
            sortedCampaigns = sortedCampaigns.slice(0, campaignsToShow);

            // Show a button to show or hide campaigns
            if (totalCampaigns > 20 && totalCampaigns > campaignsToShow) {
                sortedCampaigns.push({
                    type: 'showmore',
                    moreToShow: totalCampaigns - campaignsToShow,
                    group: campaignType.type
                });
            } else if (totalCampaigns > 20 && totalCampaigns === campaignsToShow) {
                sortedCampaigns.push({
                    type: 'showless',
                    group: campaignType.type
                });
            }

            return {
                ...campaignType,
                campaigns: sortedCampaigns,
                ...(!compactView && { totalCampaigns: totalCampaigns })
            };
        });

        return filteredCampaigns;
    };

    // Check if the rows should start collapsed or not
    // If the rows are modified once (so manually shown), don't collapse everything again
    getUpdatedCollapsed = (filteredCampaigns) => {
        const { collapsedIsModified } = this.state;

        let startCollapsed = false,
            updatedCollapsed;
        try {
            if (this.setup.campaigns.calendar.startCollapsed) startCollapsed = true;
        } catch (error) {}

        if (startCollapsed && !collapsedIsModified) {
            updatedCollapsed = filteredCampaigns.map((c) => c.type);
        }

        return updatedCollapsed;
    };

    /**
     * Checks how many campaigns there are in each group of campaigns
     */
    getUpdatedShowCount = (filteredCampaigns) => {
        const { showCount } = this.state;

        const newShowCount = { ...showCount };

        filteredCampaigns.forEach((campaignType) => {
            newShowCount[campaignType.type].totalCampaigns = campaignType.totalCampaigns;
        });

        return newShowCount;
    };

    /**
     * Check if the resources are changes and if so, refresh the campaigns
     */
    refreshFeaturedEvents = async () => {
        const { featuredEvents } = this.state;
        let newFeaturedEvents = [],
            resource = 'calendarEvents';

        if (this.props.calendarEvents) {
            resource = 'calendarEvents';
        } else if (this.props.calendar_events) {
            resource = 'calendar_events';
        }

        const data = await Request.post('resources/load', { resource });

        newFeaturedEvents = data && data.data ? data.data.events : [];

        if (featuredEvents !== newFeaturedEvents) {
            this.setState({ featuredEvents: newFeaturedEvents }, () => {
                this.updateData(this.props.events);
            });
        }
    };

    /**
     * Collapse or show a row (featured events, future campaigns, etc)
     */
    collapseRows = (type) => {
        const { collapsed } = this.state;
        let newCollapsed = collapsed.slice();

        if (collapsed && collapsed.includes(type)) {
            newCollapsed = newCollapsed.filter((c) => c !== type);
        } else {
            newCollapsed.push(type);
        }

        this.setState({ collapsed: newCollapsed, collapsedIsModified: true });
    };

    /**
     * Shows or hide the channels of a campaign
     * @param {*} id The campaign id
     */
    toggleShowChannels = (id) => {
        const { showChannels } = this.state;
        let newShowChannels = showChannels.slice();

        if (showChannels && showChannels.includes(id)) {
            newShowChannels = newShowChannels.filter((c) => c !== id);
        } else {
            newShowChannels.push(id);
        }

        this.setState({ showChannels: newShowChannels });
    };

    /**
     * Set the active date of the calendar to the actual current date
     */
    setDateToday = () => {
        this.setState({ currentDate: Date.now() });
    };

    /**
     * Sets the calendar back with one timeframe (week, month or year)
     */
    prevTimeRange = () => {
        const { currentDate, timeframe } = this.state;

        if (timeframe === 'week') {
            this.setState({ currentDate: moment(currentDate).subtract(7, 'days') });
        } else if (timeframe === 'month') {
            this.setState({ currentDate: moment(currentDate).subtract(1, 'months') });
        } else if (timeframe === 'quarterly') {
            this.setState({ currentDate: moment(currentDate).subtract(3, 'months') });
        } else if (timeframe === 'year') {
            this.setState({ currentDate: moment(currentDate).subtract(6, 'months') });
        }
    };

    /**
     * Advances the calendar with one timeframe (week, month or year)
     */
    nextTimeRange = () => {
        const { currentDate, timeframe } = this.state;

        if (timeframe === 'week') {
            this.setState({ currentDate: moment(currentDate).add(7, 'days') });
        } else if (timeframe === 'month') {
            this.setState({ currentDate: moment(currentDate).add(1, 'months') });
        } else if (timeframe === 'quarterly') {
            this.setState({ currentDate: moment(currentDate).add(3, 'months') });
        } else if (timeframe === 'year') {
            this.setState({ currentDate: moment(currentDate).add(6, 'months') });
        }
    };

    /**
     * Change the date via the datepicker
     */
    onChangeDate = (date) => {
        this.setState({ currentDate: date });
    };

    /**
     * Change the timeframe (week, month or year)
     * @param {*} timeframe
     */
    setTimeframe = (timeframe) => {
        this.setState({ timeframe });
    };

    /**
     * Go to calendar route
     */
    goToCalendar = () => {
        this.props.history.push('/campaigns/calendar');
    };

    /**
     * Make a screenshot of the current calendar and download it as an image
     */
    downloadImage = () => {
        html2canvas(document.getElementById('calendar-view')).then((canvas) => {
            const a = document.createElement('a');
            // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
            a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
            a.download = 'calendar.jpg';
            a.click();
        });
    };

    /**
     * Get the campaigns in the view and download them as a xlsx file
     */
    downloadExcel = () => {
        const { events, filters } = this.props;
        const { startDate, endDate } = this.state;

        exportToExcel(events, filters, startDate, endDate);
    };

    /**
     * Show more or less campaigns
     * @param {*} toggleShow Object that has the type of toggle (show more/less), how much more to show and which group it is
     */
    toggleShowMore = (toggleShow) => {
        const { showCount } = this.state;
        const newShowCount = { ...showCount };

        if (toggleShow.type === 'showmore') {
            const { totalCampaigns, campaignsToShow } = showCount[toggleShow.group];

            if (totalCampaigns - campaignsToShow < 20) {
                newShowCount[toggleShow.group].campaignsToShow = totalCampaigns;
            } else {
                newShowCount[toggleShow.group].campaignsToShow += 20;
            }
        } else {
            newShowCount[toggleShow.group].campaignsToShow = 20;
        }

        this.setState({ showCount: newShowCount, collapsedIsModified: true }, () => this.updateData(this.props.events));
    };

    /**
     * Update the filters object if a filter chip changes (e.g. removed).
     * @param {string} key
     * @param {string} value
     */
    onChangeFilterChips = (key, value) => {
        const { filters, onChangeFilters } = this.props;

        const newFilters = { ...filters };

        if (!!value && value.length > 0) {
            newFilters[key] = value;
            onChangeFilters(newFilters);
        } else {
            delete newFilters[key];
            onChangeFilters(newFilters);
        }
    };

    render() {
        const { onEventClick, compactView, events, filters, filterSetup, onChangeFilters } = this.props;
        const { timeframe, currentDate, filteredCampaigns, startDate, endDate, showChannels, loading, collapsed, name, featuredEvents } = this.state;

        // Check if the filterbar should be visible
        let showFilters = false;
        if (filters) {
            Object.keys(filters).forEach((filterKey) => {
                if (filterKey === 'searchTerm') return;
                if (filterKey) showFilters = true;
            });
        }

        return (
            <div className="calendar">
                {!compactView && (
                    <React.Fragment>
                        <div className="calendar__header">
                            <CalendarHeader
                                currentDate={currentDate}
                                startDate={startDate}
                                endDate={endDate}
                                timeframe={timeframe}
                                compactView={compactView}
                                setDateToday={this.setDateToday}
                                prevTimeRange={this.prevTimeRange}
                                nextTimeRange={this.nextTimeRange}
                                setTimeframe={this.setTimeframe}
                                downloadImage={this.downloadImage}
                                downloadExcel={this.downloadExcel}
                                onChangeDate={this.onChangeDate}
                            />
                        </div>
                        {showFilters && (
                            <div className="calendar__filter-bar">
                                <GenericFilterChips
                                    filterSetup={filterSetup}
                                    filters={filters}
                                    onChange={this.onChangeFilterChips}
                                    onClearAll={() => onChangeFilters({})}
                                    className="calendar__filter-bar__chips"
                                />
                            </div>
                        )}
                    </React.Fragment>
                )}
                <div className="calendar__body" data-tour-selector="calendar" id="calendar-view">
                    <div className="calendar__body__grid">
                        <Grid
                            name={name}
                            currentDate={currentDate}
                            timeframe={timeframe}
                            filteredCampaigns={filteredCampaigns}
                            onCampaignClick={onEventClick}
                            startDate={startDate}
                            endDate={endDate}
                            compactView={compactView}
                            showChannels={showChannels}
                            loading={loading}
                            collapsed={collapsed}
                            featuredEvents={featuredEvents}
                            filters={filters}
                            prevTimeRange={this.prevTimeRange}
                            nextTimeRange={this.nextTimeRange}
                            toggleShowChannels={this.toggleShowChannels}
                            toggleShowMore={this.toggleShowMore}
                            collapseRows={this.collapseRows}
                            refreshFeaturedEvents={this.refreshFeaturedEvents}
                            onChangeFilters={onChangeFilters}></Grid>

                        {!this.props.campaignsFetched && (
                            <div className="calendar__body__grid__loader">
                                <div className="calendar__body__grid__loader__spinner">
                                    <CircularProgress color="primary" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {compactView && events.length > 10 && (
                    <div className="calendar__show-more">
                        <Button onClick={() => this.goToCalendar()} color="primary">
                            Show all campaigns
                        </Button>
                    </div>
                )}
                {compactView && events.length <= 10 && (
                    <div className="calendar__show-more">
                        <Button onClick={() => this.goToCalendar()} color="primary">
                            Show full calendar
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default withResources(withRouter(Calendar), ['calendarEvents', 'calendar_events']);
