import React, { Component } from 'react';
import Popover from '@mui/material/Popover';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import moment from 'moment/moment.js';

// Icons
import Icon from 'components/ui-components-v2/Icon';
import Chip from 'components/ui-components-v2/Chip';
import CampaignStatusChip from 'components/ui-components/CampaignStatusChip';
import DateFormat from 'components/ui-components/DateFormat';
import Avatar from 'components/ui-components-v2/Avatar';
import Setup from 'components/data/Setup';

const CAMPAIGN_DESCRIPTION_MAX_LENGTH = 200;

export default class TimelineEventPopover extends Component {
    render() {
        const { campaign, open, onCampaignClick, closePopover, anchorEl } = this.props;

        let image = '';
        let campaignDescription = campaign.description || '';

        if (campaign.image) {
            image = campaign.image;
        } else if (campaign.campaignFormat) {
            image = campaign.campaignFormat.image;
        }

        // Truncate the description if it's too long
        if (campaignDescription && campaignDescription.length > CAMPAIGN_DESCRIPTION_MAX_LENGTH) {
            campaignDescription = campaignDescription.substring(0, CAMPAIGN_DESCRIPTION_MAX_LENGTH) + '...';
        }

        // In some cases, the channels are stored as an array, in other cases as a string.
        // We need to handle both cases. So convert them to an array, so the JSX can handle them..
        const channels = (() => {
            if (!campaign.channels) return null;
            if (campaign.channels.length === 0) return null;
            if (Array.isArray(campaign.channels)) {
                return campaign.channels.map((channel) => {
                    if (Setup.get('channels')[channel]) {
                        return Setup.get('channels')[channel];
                    } else {
                        return channel;
                    }
                });
            }
            if (typeof campaign.channels === 'string') {
                if (Setup.get('channels')[campaign.channels]) {
                    return [Setup.get('channels')[campaign.channels]];
                } else {
                    return [campaign.channels];
                }
            }
            return null;
        })();

        return (
            <Popover open={open} anchorEl={anchorEl} style={{ boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.3)' }} transitionDuration={150}>
                <ClickAwayListener onClickAway={() => closePopover()}>
                    <div className="calendar-timeline-event__popover">
                        <div
                            className="calendar-timeline-event__popover__header"
                            style={{
                                backgroundImage: `url('${image}')`
                            }}>
                            <div className="calendar-timeline-event__popover__header__top">
                                <div className="calendar-timeline-event__popover__header__top__status">
                                    <CampaignStatusChip status={campaign.status} />
                                </div>
                                <div className="calendar-timeline-event__popover__header__top__edit">
                                    <IconButton color="inherit" onClick={() => onCampaignClick(campaign)}>
                                        <Icon>edit</Icon>
                                    </IconButton>
                                </div>
                                <div className="calendar-timeline-event__popover__header__top__close">
                                    <IconButton color="inherit" onClick={() => closePopover()}>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </div>
                            <div className="calendar-timeline-event__popover__header__title">{campaign.title}</div>
                            <div className="calendar-timeline-event__popover__header__gradient"></div>
                        </div>

                        <div className="calendar-timeline-event__popover__details">
                            <div className="calendar-timeline-event__popover__details__category">
                                {campaign && campaign.campaignFormat ? campaign.campaignFormat.title : ''}
                            </div>
                            <div className="calendar-timeline-event__popover__details__date">
                                <div className="calendar-timeline-event__popover__details__date__start">
                                    <Icon fontSize="inherit">arrow_forward</Icon>
                                    <span>{moment(campaign.dateOnline).format('DD MMM YYYY')} </span>
                                    <span className="calendar-timeline-event__popover__details__date__dot">&middot;</span>
                                    <span> {moment(campaign.dateOnline).format('HH:mma')}</span>
                                </div>
                                <div className="calendar-timeline-event__popover__details__date__end">
                                    <Icon fontSize="inherit">arrow_back</Icon>
                                    <span>{moment(campaign.dateOffline).format('DD MMM YYYY')}</span>
                                    <span className="calendar-timeline-event__popover__details__date__dot">&middot;</span>
                                    <span>{moment(campaign.dateOffline).format('HH:mma')}</span>
                                </div>
                            </div>
                            {campaignDescription && (
                                <div className="calendar-timeline-event__popover__details__description">
                                    <div className="calendar-timeline-event__popover__info__title">Description</div>
                                    {campaignDescription}
                                </div>
                            )}
                        </div>

                        {(campaign.goal || campaign.market || campaign.channels || Array.isArray(campaign.tags)) && (
                            <div className="calendar-timeline-event__popover__info">
                                {campaign.goal && (
                                    <div className="calendar-timeline-event__popover__info__section">
                                        <div className="calendar-timeline-event__popover__info__title">Phase</div>
                                        <div className="calendar-timeline-event__popover__info__goal">
                                            <Chip size="small" style={{ marginRight: 8, marginBottom: 8 }} label={campaign.goal} />
                                        </div>
                                    </div>
                                )}
                                {campaign.market && campaign.market !== '' && (
                                    <div className="calendar-timeline-event__popover__info__section">
                                        <div className="calendar-timeline-event__popover__info__title">Market</div>
                                        <div className="calendar-timeline-event__popover__info__market">
                                            <Chip size="small" style={{ marginRight: 8, marginBottom: 8 }} label={campaign.market} />
                                        </div>
                                    </div>
                                )}
                                {Array.isArray(campaign.tags) && campaign.tags.length > 0 && (
                                    <div className="calendar-timeline-event__popover__info__section">
                                        <div className="calendar-timeline-event__popover__info__title">Tags</div>
                                        <div className="calendar-timeline-event__popover__info__market">
                                            {campaign.tags.map((tag) => (
                                                <Chip size="small" key={tag} style={{ marginRight: 8, marginBottom: 8 }} label={tag} />
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {channels && (
                                    <div className="calendar-timeline-event__popover__info__section">
                                        <div className="calendar-timeline-event__popover__info__title">Enabled channels</div>
                                        <div className="calendar-timeline-event__popover__info__channels">
                                            {channels.map((channel, i) => {
                                                return <Chip size="small" key={i} style={{ marginRight: 8, marginBottom: 8 }} label={channel} />;
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {campaign.user && (
                            <div className="calendar-timeline-event__popover__user">
                                <div className="calendar-timeline-event__popover__user__image">
                                    <Avatar src={campaign.user.profilePicture} size="large">
                                        {campaign.user.name}
                                    </Avatar>
                                </div>
                                <div className="calendar-timeline-event__popover__user__data">
                                    <div className="calendar-timeline-event__popover__user__data__name">
                                        {campaign && campaign.user ? campaign.user.name : ''}
                                    </div>
                                    <div className="calendar-timeline-event__popover__user__data__last-updated">
                                        <span>Updated: </span> <DateFormat date={campaign.dateLastUpdate} type="dateTime" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </ClickAwayListener>
            </Popover>
        );
    }
}
