import React from 'react';
import moment from 'moment';
import Calendar from 'components/campaigns/Calendar/components';
import fetchFlightData from 'components/campaigns/CampaignFlightManager/helpers/fetchFlightData';
import '../styles/main.scss';

/**
 * CampaignFlightCalendar
 * This component displays all the campaign flights
 */
class CampaignFlightCalendar extends React.Component {
    constructor(props) {
        super(props);

        let events = props.value ? props.value : [];

        if (Array.isArray(events)) {
            events = events.map(fetchFlightData).map((flight) => ({
                ...flight,
                dateOnline: flight.online,
                dateOffline: flight.offline
            }));
        }

        if (Array.isArray(events)) {
            events = events.filter((event) => event.dateOnline);
        }

        const startDate = this.getCalendarStartDate(events);

        this.state = {
            events,
            startDate
        };
    }

    /**
     * Update the state on changes
     * This reflects the new items in the calendar in case those changes
     * @param {*} props
     * @param {*} state
     */
    static getDerivedStateFromProps(props, state) {
        let events = props.value ? props.value : [];

        if (Array.isArray(events)) {
            events = events.map(fetchFlightData).map((flight) => ({
                ...flight,
                dateOnline: flight.online,
                dateOffline: flight.offline
            }));
        }

        if (events && events.length) {
            return { events };
        }

        return state;
    }

    /**
     * Returns the date of the first event from today. This will determine the starting month of the calendar
     * @param {*} events - The list of events
     */
    getCalendarStartDate = (events) => {
        let eventsAfterToday = [];

        if (Array.isArray(events) && events.length > 0) {
            eventsAfterToday = events.filter((event) => moment(event.dateOnline) >= moment(new Date()).startOf('month'));
            if (eventsAfterToday.length <= 0) return new Date();

            eventsAfterToday.sort((a, b) => moment(a.dateOnline) - moment(b.dateOnline));
            return eventsAfterToday[0].dateOnline;
        } else {
            return new Date();
        }
    };

    render() {
        const { events, startDate } = this.state;

        return (
            <div className="campaign-flight-calendar">
                <Calendar events={events} currentDate={startDate} {...this.props} name="flights" />
            </div>
        );
    }
}

export default CampaignFlightCalendar;
