import React from 'react';
import '../styles/timeline-event.scss';
import IconButton from '@mui/material/IconButton';
import moment from 'moment/moment.js';
import classNames from 'classnames';
// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from 'components/ui-components-v2/Typography';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Icon from 'components/ui-components-v2/Icon';
import Setup from 'components/data/Setup';
import Translation from 'components/data/Translation';
import getCategoryColor from '../helpers/getCategoryColor';
import TimelineEventPopover from './timeline-event-popover';

/**
 * Class CalendarTimelineEvent
 * Adds the TimelineEvent interface of the calendar
 */
class CalendarTimelineEvent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            popoverOpen: false
        };

        this.setup = Setup.get();
    }

    onClickTimelineEvent = (event, campaign) => {
        const { collapseRows } = this.props;

        event.preventDefault();
        event.stopPropagation();

        const { toggleShowChannels } = this.props;
        const target = event.currentTarget;

        if (campaign.planning && campaign.planning.channels && campaign.type === 'campaign') {
            if (toggleShowChannels) toggleShowChannels(campaign.id);
        } else if (campaign.group === 'combined' && campaign.category) {
            collapseRows(campaign.category);
        } else {
            this.openPopover(target);
        }
    };

    /**
     * Shows the active popover
     * @param {*} target - The event target
     */
    openPopover = (target) => {
        this.setState({
            anchorEl: target,
            popoverOpen: true
        });
    };

    /**
     * Closes the popover active popover
     */
    closePopover = () => {
        this.setState({
            anchorEl: null,
            popoverOpen: false
        });
    };

    /**
     * Creates the tooltip for the timeline event
     * @param {*} campaign The data of the campaign
     * @returns The string of the tooltip
     */
    getTooltip = (campaign) => {
        if (campaign.type === 'channel' || campaign.type === 'featured' || campaign.group === 'combined') return '';
        let string = '';

        const dateOnline = moment(campaign.dateOnline).valueOf();
        const dateOffline = moment(campaign.dateOffline).valueOf();

        // Add a time status as the first line of the tooltip
        if (dateOnline > Date.now()) {
            string += `${Translation.get('calendar.group.future', 'campaigns')} campaign \n`;
        } else if (dateOnline < Date.now() && dateOffline > Date.now()) {
            string += `${Translation.get('calendar.group.current', 'campaigns')} campaign \n`;
        } else {
            string += `${Translation.get('calendar.group.past', 'campaigns')} campaign \n`;
        }

        if (campaign.title) {
            string += campaign.title + '\n';
        }
        if (campaign.status) {
            if (campaign.status === 'inreview') campaign.status = 'In review';
            string += campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1);
        }
        if (campaign.goal) {
            string += campaign.goal.charAt(0).toUpperCase() + campaign.goal.slice(1);
        }
        if (campaign.brand) string += ' | ' + campaign.brand.charAt(0).toUpperCase() + campaign.brand.slice(1);
        if (campaign.market) string += ' | ' + campaign.market.charAt(0).toUpperCase() + campaign.market.slice(1);
        if (campaign.department) string += ' | ' + campaign.department.charAt(0).toUpperCase() + campaign.department.slice(1);
        if (Array.isArray(campaign.tags)) {
            campaign.tags.forEach((tag, index) => {
                if (index === 0) {
                    string += ` | ${tag}`;
                } else {
                    string += ` - ${tag}`;
                }
            });
        }

        return <div className="calendar-timeline-event__tooltip">{string}</div>;
    };

    /**
     * Returns the icon, based on the status
     * @param {*} campaign - The campaign
     */
    renderIcon = (campaign) => {
        const { status } = campaign;
        let iconName;

        if (campaign.type === 'channel') return;

        if (status === 'upcoming') {
            iconName = 'update';
        } else if (status === 'live') {
            iconName = 'public';
        } else if (status === 'scheduled') {
            iconName = 'schedule';
        } else if (status === 'submitted') {
            iconName = 'assignment_turned_in';
        } else if (status === 'inreview') {
            iconName = 'assignment_returned';
        } else if (status === 'removed') {
            iconName = 'delete';
        } else if (status === 'declined') {
            iconName = 'assignment_late';
        } else if (status === 'draft') {
            iconName = 'assignment';
        } else if (status === 'request') {
            iconName = 'note_alt';
        } else if (status === 'create') {
            iconName = 'create';
        } else if (status === 'featured') {
            iconName = 'star';
        } else if (status === 'pending') {
            iconName = 'pending_actions';
        } else if (status === 'published') {
            iconName = 'published_with_changes';
        } else if (status === 'offline') {
            iconName = 'unpublished';
        } else if (status === 'unpublished') {
            iconName = 'unpublished';
        } else return null;

        return <Icon className="calendar-timeline-event__status-icon__icon">{iconName}</Icon>;
    };

    /**
     * Returns the color of a combined timeline event
     */
    getCombinedTimelineEventColor = (campaign) => {
        const { groupIndex } = this.props;

        let timelineEventColor;

        if (
            campaign.status === 'draft' ||
            campaign.status === 'request' ||
            campaign.status === 'pending' ||
            (campaign.status === 'offline' && campaign.group !== 'past')
        ) {
            timelineEventColor = 'disabled';
        } else if (campaign.category) {
            timelineEventColor = getCategoryColor(campaign.category, groupIndex);
        } else {
            timelineEventColor = campaign.group;
        }

        return timelineEventColor;
    };

    /**
     * Returns the name that is added to the classname and gets its styles from the stylesheet
     */
    getColorClass = (campaign) => {
        // If the campaign has a specific color defined, return without a classname (it will get the background color that is filled in)
        if (campaign.color) return;

        const defaultGroups = ['past', 'current', 'future', 'unused', 'disabled', 'featured', 'placeholder'];

        if (defaultGroups.includes(campaign.group)) {
            return campaign.group;
        }

        const dateOnline = moment(campaign.dateOnline).valueOf();
        const dateOffline = moment(campaign.dateOffline).valueOf();

        if (dateOnline > Date.now()) {
            return 'future';
        } else if (dateOnline < Date.now() && dateOffline > Date.now()) {
            return 'current';
        } else {
            return 'past';
        }
    };

    render() {
        const { campaign, style, onCampaignClick, campaignStartedBefore, campaignEndsLater, showChannels, width, showTimelineEventIcon } = this.props;
        const { anchorEl, popoverOpen } = this.state;

        // Don't show an event if it's the combined placeholder campaign
        if (campaign.group === 'combined' && campaign.category === 'placeholder') return <React.Fragment></React.Fragment>;

        // Format tags
        if (campaign.tags) {
            try {
                campaign.tags = JSON.parse(campaign.tags);
                campaign.tags = campaign.tags.map((tag) => {
                    if (this.setup.campaigns.tags) {
                        return this.setup.campaigns.tags[tag];
                    }
                    return tag;
                });
            } catch (error) {
                // no campaign tags
            }
        }

        const updatedStyle = { ...style };

        // Change the layout of an event if the event does not fully fit the specific timeframe
        if (campaignStartedBefore) {
            updatedStyle.borderLeft = 'none';
            updatedStyle.borderTopLeftRadius = '0';
            updatedStyle.borderBottomLeftRadius = '0';
        }
        if (campaignEndsLater) {
            updatedStyle.borderRight = 'none';
            updatedStyle.borderTopRightRadius = '0';
            updatedStyle.borderBottomRightRadius = '0';
        }
        if (campaign.category && campaign.group === 'combined') {
            updatedStyle.background = this.getCombinedTimelineEventColor(campaign);
            updatedStyle.color = updatedStyle.background === '#374045' ? 'white' : 'black';
        } else if (campaign.color) {
            updatedStyle.background = campaign.color;
            updatedStyle.color = 'black';
        }

        let useCategories = false;

        try {
            if (this.setup.campaigns.categories === true) {
                useCategories = true;
            }
        } catch (error) {
            // don't use categories
        }

        const channelsOpen = showChannels && showChannels.includes(campaign.id) && campaign.type === 'campaign';

        return (
            <React.Fragment>
                <Tooltip title={this.getTooltip(campaign)} arrow={true}>
                    <div
                        style={updatedStyle}
                        ref={(event) => (this.eventElement = event)}
                        onClick={(e) => this.onClickTimelineEvent(e, campaign)}
                        className={`calendar-timeline-event calendar-timeline-event--${this.getColorClass(campaign)}`}>
                        {(campaign.category || campaign.group) && campaign.group !== 'placeholder' && (
                            <div
                                className="calendar-timeline-event__category"
                                style={{
                                    background: getCategoryColor(campaign.category ? campaign.category : campaign.group, true)
                                }}></div>
                        )}

                        {!campaignStartedBefore && (
                            <React.Fragment>
                                {width > 4 && campaign.group !== 'combined' && showTimelineEventIcon && this.renderIcon(campaign) && (
                                    <div className={'calendar-timeline-event__status-icon'}>{this.renderIcon(campaign)}</div>
                                )}
                                {campaign.group !== 'combined' && (
                                    <div className={`calendar-timeline-event__copy calendar-timeline-event__copy--${this.getColorClass(campaign)}`}>
                                        <Typography variant="h6" component="div" noWrap>
                                            <b>{campaign.title}</b>
                                        </Typography>
                                        <Typography variant="caption" component="div" noWrap>
                                            {campaign.status}
                                            {campaign.brand ? ` | ${campaign.brand}` : ''}
                                            {campaign.market ? ` | ${campaign.market}` : ''}
                                            {campaign.department ? ` | ${campaign.department}` : ''}
                                            {campaign.department && Array.isArray(campaign.tags) && ' | '}
                                            {Array.isArray(campaign.tags) &&
                                                campaign.tags.map((tag, index) => (index === campaign.tags.length - 1 ? tag : `${tag} - `))}
                                        </Typography>
                                    </div>
                                )}
                            </React.Fragment>
                        )}

                        {campaign.planning && campaign.planning.channels && campaign.type === 'campaign' && (
                            <IconButton size="small" style={{ marginLeft: 'auto' }}>
                                {!channelsOpen ? (
                                    <KeyboardArrowDownIcon className={`calendar-timeline-event__icon--${campaign.group}`} />
                                ) : (
                                    <KeyboardArrowUpIcon className={`calendar-timeline-event__icon--${campaign.group}`} />
                                )}
                            </IconButton>
                        )}

                        {campaign.group === 'combined' && (
                            <Typography
                                variant="h4"
                                component="div"
                                color={'text.primary'}
                                className={`calendar-timeline-event__combined calendar-timeline-event__combined${
                                    campaignStartedBefore ? '--started-before' : ''
                                }`}>
                                {campaign.campaigns ? `${campaign.campaigns.length} campaigns` : null}
                            </Typography>
                        )}
                    </div>
                </Tooltip>
                <TimelineEventPopover
                    campaign={campaign}
                    onCampaignClick={onCampaignClick}
                    open={popoverOpen && (campaign.type === 'campaign' || campaign.type === 'channel')}
                    closePopover={() => this.closePopover()}
                    anchorEl={anchorEl}
                />
            </React.Fragment>
        );
    }
}

export default CalendarTimelineEvent;
