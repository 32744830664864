import moment from 'moment';
import Setup from 'components/data/Setup';
import getInitialCampaignListStructure from './getInitialCampaignListStructure';
import getGroupBy from './getGroupBy';

/**
 * Get the structure of the campaign list (seperated by groups or categories) and check for each campaign/event in which category it fits
 * @param {*} campaignsAndEvents
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} filters
 * @return The updated list of campaigns, seperated by a group/category
 */
const addCampaignsToCampaignList = (campaignsAndEvents, startDate, endDate, filters) => {
    const setup = Setup.get();
    const campaignList = getInitialCampaignListStructure();

    if (campaignsAndEvents) {
        campaignsAndEvents.forEach((campaign) => {
            if (!campaign.title) return;

            const dateOnline = moment(campaign.dateOnline).valueOf();
            const dateOffline = moment(campaign.dateOffline).valueOf();

            // Remove incorrect campaigns
            if (dateOnline > dateOffline) return false;

            // Remove campaigns that are outside of the range
            if (dateOnline > endDate || dateOffline < startDate) return false;

            const groupBy = getGroupBy();

            // Check if placeholder campaigns should be visible
            let placeholderCampaignsPath = null;
            Setup.getValueFromModel('setup.campaigns.calendar.placeholderCampaignsPath');
            if (Setup.getValueFromModel('setup.campaigns.calendar.placeholderCampaignsPath')) {
                placeholderCampaignsPath = Setup.getValueFromModel('setup.campaigns.calendar.placeholderCampaignsPath');
            }

            if (groupBy) {
                let campaignGroup = groupBy === 'category' ? campaign.category : campaign[groupBy];
                if (placeholderCampaignsPath && campaign.campaignFormat && campaign.campaignFormat.path === placeholderCampaignsPath)
                    campaignGroup = 'placeholder';

                const correctIndex = campaignList.findIndex((i) => i.type === campaignGroup);

                // Don't show featured campaigns if the are active filters
                if (campaign.status === 'featured' && Object.keys(filters).length > 1) {
                    return;
                }

                // Check in which index of the campaignList array a campaign belongs
                if (correctIndex === -1) {
                    campaign.group = 'uncategorized';
                    campaignList[campaignList.length - 1].campaigns.push(campaign);
                } else {
                    campaign.group = campaignGroup;
                    campaignList[correctIndex].campaigns.push(campaign);
                }
            } else {
                if (placeholderCampaignsPath && campaign.campaignFormat && campaign.campaignFormat.path === placeholderCampaignsPath) {
                    // Push to placeholder events
                    campaign.group = 'placeholder';
                    const index = campaignList.findIndex((c) => c.type === 'placeholder');
                    campaignList[index].campaigns.push(campaign);
                } else if (campaign.status === 'featured') {
                    // Push to featured events
                    campaign.group = 'featured';
                    const index = campaignList.findIndex((c) => c.type === 'featured');

                    // Don't show featured campaigns if there are active filters
                    if (filters && Object.keys(filters).length < 2) {
                        campaignList[index].campaigns.push(campaign);
                    }
                } else if (campaign.status === 'removed' || campaign.status === 'declined') {
                    // Push to unused campaigns
                    campaign.group = 'unused';
                    const index = campaignList.findIndex((c) => c.type === 'unused');
                    campaignList[index].campaigns.push(campaign);
                } else if (dateOnline > Date.now()) {
                    // Push to future campaigns
                    campaign.group = 'future';
                    const index = campaignList.findIndex((c) => c.type === 'future');
                    campaignList[index].campaigns.push(campaign);
                } else if (dateOnline < Date.now() && dateOffline > Date.now()) {
                    // Push to current campaigns
                    campaign.group = 'current';
                    const index = campaignList.findIndex((c) => c.type === 'current');
                    campaignList[index].campaigns.push(campaign);
                } else {
                    // Push to past campaigns
                    campaign.group = 'past';
                    const index = campaignList.findIndex((c) => c.type === 'past');
                    campaignList[index].campaigns.push(campaign);
                }
            }
        });
    }

    return campaignList;
};

export default addCampaignsToCampaignList;
