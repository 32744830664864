import CampaignFlightManager from './components/index';
import EditorDynamicDataWrapper from '.././../editor-data/EditorDynamicDataWrapper';

const CampaignFlightManagerDynamicData = EditorDynamicDataWrapper(CampaignFlightManager);

export default CampaignFlightManagerDynamicData;
export {
    CampaignFlightManager,
    CampaignFlightManagerDynamicData
}
