import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Tooltip from 'components/ui-components-v2/Tooltip';

const GridHeaderDatesBottom = ({ cells, timeframe, startDate, cellWidthPerc, cellCount }) => {
    const bottomDates = [];

    for (let i = 0; i < cellCount; i++) {
        let isToday = false,
            isWeekend = false;

        // Check if the date is today or this week
        if ((timeframe === 'week' || timeframe === 'month') && moment(startDate).add(i, 'days').isSame(Date.now(), 'day')) {
            isToday = true;
        } else if ((timeframe === 'quarterly' || timeframe === 'year') && moment(startDate).add(i, 'weeks').isSame(Date.now(), 'week')) {
            isToday = true;
        }

        // Check if the date is a weekend day
        if ((timeframe === 'week' || timeframe === 'month') && (moment(startDate).add(i, 'days').day() === 0 || moment(startDate).add(i, 'days').day() === 6)) {
            isWeekend = true;
        }

        bottomDates.push(
            <Tooltip title={`${isToday ? `Current ${timeframe === 'quarterly' || timeframe === 'year' ? 'week' : 'day'}` : ''}`} arrow={isToday} key={i}>
                <div
                    className={classNames('calendar-grid-header__dates__bottom__cell', { ['isWeekend']: isWeekend, ['isToday']: isToday })}
                    style={{
                        width: `${timeframe === 'quarterly' || timeframe === 'year' ? cells[i] : cellWidthPerc}%`
                    }}>
                    {timeframe === 'week' && <span>{moment(startDate).add(i, 'days').format('ddd D')}</span>}
                    {timeframe === 'month' && <span>{moment(startDate).add(i, 'days').format('D')}</span>}
                    {(timeframe === 'quarterly' || timeframe === 'year') && <span>W {moment(startDate).add(i, 'weeks').format('W')}</span>}
                </div>
            </Tooltip>
        );
    }

    return bottomDates;
};

export default GridHeaderDatesBottom;
