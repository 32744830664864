import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import EditorData from 'components/editor-data/EditorData';
import { withResources } from 'components/data/Resources';
import CampaignFlightManagerTable from './table';
import fetchFlightData from '../helpers/fetchFlightData';

/**
 * CampaignFlightManager
 * Component to manage multiple flights in one campaign based on the CDJ phases
 */
class CampaignFlightManager extends React.Component {
    constructor(props) {
        super(props);
        const uuid = 'i_' + uuidv4().replace(/-/g, '');

        // Check if value is already set. If not, create a new setup
        const value =
            this.props.value && Array.isArray(this.props.value)
                ? this.props.value.map(fetchFlightData)
                : [
                      {
                          id: uuid,
                          online: moment().format('YYYY-MM-DD'),
                          offline: moment().add(7, 'days').format('YYYY-MM-DD')
                      }
                  ];

        this.state = { value, confirmDialogOpen: false };
    }

    /**
     * Proxy mutations to global state .6s after last state change
     */
    startMutate = (mutatedFlight) => {
        clearTimeout(this.timeoutMutate);
        this.timeoutMutate = setTimeout(() => {
            // Store flight data
            if (mutatedFlight) {
                EditorData.setModel(`flights.${mutatedFlight.id}.settings.goal`, mutatedFlight.goal);
                EditorData.setModel(`flights.${mutatedFlight.id}.settings.planning.online`, mutatedFlight.online);
                EditorData.setModel(`flights.${mutatedFlight.id}.settings.planning.offline`, mutatedFlight.offline);
            }

            // store flight configuration list
            this.props.onMutation(this.state.value.map((flightRow) => ({ id: flightRow.id, title: flightRow.title })));
        }, 600);
    };

    /**
     * Add an empty row,
     */
    handleRowAdd = () => {
        const uuid = 'i_' + uuidv4().replace(/-/g, '');
        const newRow = {
            id: uuid,
            online: moment().add(2, 'days').format('YYYY-MM-DD'),
            offline: moment().add(8, 'days').format('YYYY-MM-DD')
        };

        this.setState(
            {
                value: [...this.state.value, newRow]
            },
            () => this.startMutate(newRow)
        );
    };

    /**
     * Select the given row by id, clone it and ad a new unique uuid
     */
    handleRowDuplicate = (id) => () => {
        const uuid = 'i_' + uuidv4().replace(/-/g, '');
        const rowOrigin = this.state.value.filter((row) => row.id === id);
        const rowClone = { ...rowOrigin[0], id: uuid };

        // Clone old data
        const originalFlightData = EditorData.getValueFromModel('flights.' + id);
        EditorData.setModel('flights.' + uuid, cloneDeep(originalFlightData));

        this.setState(
            {
                value: [...this.state.value, rowClone]
            },
            () => this.startMutate(rowClone)
        );
    };

    /**
     * Handle value change of existing row
     */
    handleRowChange = (id, model) => (newValue) => {
        const mutatedFlight = this.state.value.find((row) => row.id === id);
        if (!mutatedFlight) return;

        const mappedNewValue = this.mapCampaignGoal(newValue);

        // Add new data to row object
        mutatedFlight[model] = newValue;

        // If model is goal and there is no title jet, generate auto increment name
        if (!mutatedFlight.title && model === 'goal') {
            mutatedFlight.title = mappedNewValue + ' ' + this.state.value.filter((item) => item.goal === newValue).length;
        }

        // Set new list to state and update the campaign model. Also replace old flight with mutated flight
        this.setState(
            {
                value: this.state.value.map((flight) => (flight.id !== id ? flight : mutatedFlight))
            },
            () => {
                this.startMutate(mutatedFlight);
            }
        );
    };

    /**
     * This checks if there is a phase stage mapping available in the resources. If so, it takes the label from the resources
     * Example: Philips uses 'See' as a phase, but they want to display it as 'Awareness'
     * @param {String} goal
     * @returns The label of the goal
     */
    mapCampaignGoal = (goal) => {
        const { dcmConfig } = this.props;

        if (dcmConfig && dcmConfig.mapping && dcmConfig.mapping.phaseStage && dcmConfig.mapping.phaseStage[goal.toLowerCase()]) {
            return dcmConfig.mapping.phaseStage[goal.toLowerCase()];
        }

        return goal;
    };

    /**
     * Handle remove button click. Save id in state and trigger confirm dialog by doing so
     */
    handleRowRemove = (id) => () => {
        this.setState({ confirmDeleteId: id });
    };

    /**
     * Handle the confirm dialog result. If status == false, cancel remove process
     */
    handleRemoveConfirm = (status) => () => {
        const id = this.state.confirmDeleteId;
        if (!status) return this.setState({ confirmDeleteId: null });

        const value = this.state.value.filter((row) => row.id !== id);

        this.setState({ value, confirmDeleteId: false }, () => {
            this.startMutate();
            EditorData.removeItem(`flights.${id}`);
        });
    };

    render() {
        const { value, confirmDeleteId = false } = this.state;
        const { dcmConfig } = this.props;

        return (
            <React.Fragment>
                <CampaignFlightManagerTable
                    rows={value}
                    dcmConfig={dcmConfig}
                    onChange={this.handleRowChange}
                    onDuplicate={this.handleRowDuplicate}
                    onRemove={this.handleRowRemove}
                    onNew={this.handleRowAdd}
                />
                <ConfirmDialog
                    open={confirmDeleteId}
                    title="Remove flight"
                    description="Are you sure that you want to remove this flight?"
                    onConfirm={this.handleRemoveConfirm(true)}
                    onClose={this.handleRemoveConfirm(false)}
                />
            </React.Fragment>
        );
    }
}

export default withResources(CampaignFlightManager, ['dcmConfig']);
