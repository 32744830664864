/**
 * We have this because in the setup the plural form is always used (markets, departments, etc), but in
 * each campaign the singular form is used (market: ["EN"], etc). This is the way to link to two together, without needing a lot of if/else statements
 */
const groupMapping = {
    market: 'markets',
    department: 'departments',
    brand: 'brands',
    category: 'categories'
};

export default groupMapping;
