const getPhaseStages = (dcmConfig) => {
    let phaseStages = [
        {
            key: 'See',
            label: 'See'
        },
        {
            key: 'Think',
            label: 'Think'
        },
        {
            key: 'Do',
            label: 'Do'
        },
        {
            key: 'Care',
            label: 'Care'
        }
    ];

    if (dcmConfig && dcmConfig.mapping && dcmConfig.mapping.phaseStage) {
        const resourcePhaseStages = dcmConfig.mapping.phaseStage;

        phaseStages = phaseStages.map((phaseStage) => {
            if (phaseStage.key && resourcePhaseStages[phaseStage.key.toLowerCase()]) {
                phaseStage.label = resourcePhaseStages[phaseStage.key.toLowerCase()];
            }

            return phaseStage;
        });
    }

    return phaseStages;
};

export default getPhaseStages;
