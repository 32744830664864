import getCombinedCampaigns from './getCombinedCampaigns';
import getChannelCampaign from './getChannelCampaign';

/**
 * Format the filtered campaigns to a list of rows that will be visible in the calendar
 * @param {*} campaigns The campaigns by group
 * @param {*} minimumRows The minimum amount of rows that the campaign should have
 * @returns A formatted list of campaign rows
 */
const mapFilteredCampaignsToRows = (filteredCampaigns, minimumRows, compactView, collapsed, showChannels, filters) => {
    const campaignsList = [];

    filteredCampaigns.forEach((campaignType, index) => {
        if (!(index === 0 && compactView)) {
            // Filter type 'show more' and 'show less'
            const campaigns = campaignType.campaigns.filter((c) => c.type == 'campaign' || c.goal);

            const combinedCampaigns = getCombinedCampaigns(campaigns, campaignType.type);

            campaignsList.push({
                type: campaignType.type,
                layout: 'subheader',
                campaignsCount: campaigns ? campaigns.length : 0,
                totalCampaigns: campaignType.totalCampaigns,
                combinedCampaigns
            });
        }

        // If this type is collapse, don't return any campaigns
        if (collapsed.includes(campaignType.type)) return;

        campaignType.campaigns.forEach((campaign) => {
            campaignsList.push(campaign);

            // If the campaign has subchannels with different dates, add them to the array
            if (showChannels.includes(campaign.id)) {
                try {
                    Object.keys(campaign.planning.channels).forEach((channel) => {
                        const activeChannelFilters = filters.channels ? filters.channels : [];

                        // If there are any filters on channels, exlucde the ones that are not included in the filters
                        if (activeChannelFilters.length > 0 && !activeChannelFilters.includes(channel)) return;

                        const subplanning = campaign.planning.channels[channel].subplanning;

                        // If there is a subplanning, loop over those subchannels
                        if (subplanning) {
                            Object.keys(subplanning).forEach((key) => {
                                const channelCampaign = getChannelCampaign(campaign, key, subplanning);
                                campaignsList.push(channelCampaign);
                            });
                        } else {
                            const channelCampaign = getChannelCampaign(campaign, channel);
                            campaignsList.push(channelCampaign);
                        }
                    });
                } catch (err) {
                    console.error('Error when getting subchannels', err);
                }
            }
        });
    });

    // If there are not enough campaigns to show, add some rows to fill up the space (otherwise it looks ugly)
    if (campaignsList.length < minimumRows) {
        let campaignsLength = campaignsList.length;
        if (campaignsLength < 1) campaignsLength = 1;
        const difference = minimumRows - campaignsLength;
        for (let i = 0; i < difference; i++) {
            campaignsList.push({
                type: 'fill',
                layout: 'fill'
            });
        }
    }

    return campaignsList;
};

export default mapFilteredCampaignsToRows;
