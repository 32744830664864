import moment from 'moment';

/**
 * Returns a channelCampaign
 * @param {*} campaign The campaign
 * @param {*} channel (optional) The key of the subchannel
 * @param {*} subplanning (optional) If there is a subplanning, get some data from the subplanning
 */
const getChannelCampaign = (campaign, channel, subplanning) => {
    let dateOnline, dateOffline;
    const channelCampaign = { ...campaign };

    if (subplanning) {
        dateOnline = subplanning[channel].date || subplanning[channel].online;
        dateOffline = subplanning[channel].date || subplanning[channel].offline;
        channelCampaign.channel = channel;
    } else {
        dateOnline = campaign.planning.channels[channel].online;
        dateOffline = campaign.planning.channels[channel].offline;
        channelCampaign.channel = channel;
    }

    channelCampaign.type = 'channel';
    channelCampaign.dateOnline = dateOnline;
    channelCampaign.dateOffline = dateOffline;

    if (moment(dateOnline).valueOf() > Date.now()) {
        channelCampaign.group = 'future';
    } else if (moment(dateOnline).valueOf() < Date.now() && moment(dateOffline).valueOf() > Date.now()) {
        channelCampaign.group = 'current';
    } else {
        channelCampaign.group = 'past';
    }

    return channelCampaign;
};

export default getChannelCampaign;
