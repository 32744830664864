import React from 'react';
import '../styles/grid.scss';
import moment from 'moment';
import Setup from 'components/data/Setup';
import TimelineEvent from './timeline-event';

import Subheader from './grid-rows-subheader';
import getGroupBy from '../helpers/getGroupBy';
import mapFilteredCampaignsToRows from '../helpers/mapFilteredCampaignsToRows';

/**
 * Class CalendarGridCells
 * Adds the grid interface of the calendar
 */
class CalendarGridCells extends React.Component {
    constructor(props) {
        super(props);
        this.setup = Setup.get();
        this.state = { groupBy: getGroupBy() };
    }

    render() {
        const {
            currentDate,
            filteredCampaigns,
            cellCount,
            componentWidth,
            collapsed,
            compactView,
            loading,
            name,
            featuredEvents,
            showChannels,
            filters,
            refreshFeaturedEvents,
            toggleShowMore
        } = this.props;
        const { groupBy } = this.state;

        const cellWidth = (componentWidth / cellCount / componentWidth) * 100;
        const minimumRows = compactView ? 3 : 15;
        const rows = mapFilteredCampaignsToRows(filteredCampaigns, minimumRows, compactView, collapsed, showChannels, filters);
        const noCampaigns = rows.length === 0 && !loading ? true : false;

        // Make an array of the subheaders (so these will be the groups/categories that are available in the view)
        const subheaderGroups = rows.filter((item) => item.layout === 'subheader');

        return (
            <div className="calendar-grid-rows">
                {noCampaigns && (
                    <div className="calendar-grid-rows__empty-row">
                        {!compactView && <span>No {name} found within this timeframe</span>}
                        {compactView && (
                            <span>
                                No {name} found in {moment(currentDate).format('MMMM YYYY')}
                            </span>
                        )}
                    </div>
                )}

                {rows.map((campaign, i) => {
                    const groupIndex = subheaderGroups.findIndex((item) => item.type === (campaign[groupBy] ? campaign[groupBy] : campaign.type));

                    return (
                        <div
                            className={`calendar-grid-rows__row ${campaign.layout === 'subheader' ? 'calendar-grid-rows__row-subheader' : 'hover'}`}
                            key={`${campaign.id}${campaign.type}${i}`}>
                            <div className="calendar-grid-rows__row__sidebar">
                                <Subheader
                                    campaign={campaign}
                                    groupIndex={groupIndex}
                                    collapsed={collapsed}
                                    name={name}
                                    compactView={compactView}
                                    featuredEvents={featuredEvents}
                                    onCampaignClick={this.onCampaignClick}
                                    collapseRows={this.collapseRows}
                                    toggleShowMore={toggleShowMore}
                                    refreshFeaturedEvents={refreshFeaturedEvents}
                                />
                            </div>
                            <div className="calendar-grid-rows__row__body">
                                <div className={'calendar-grid-rows__row__body__cells'}>
                                    {this.renderCells(cellCount, cellWidth)}

                                    {campaign.layout !== 'fill' &&
                                        campaign.layout !== 'subheader' &&
                                        campaign.type !== 'showmore' &&
                                        campaign.type !== 'showless' &&
                                        this.renderTimelineEvent(campaign, undefined, groupIndex)}

                                    {campaign.layout === 'subheader' &&
                                        campaign.combinedCampaigns &&
                                        campaign.combinedCampaigns.map((combinedCampaign, combinedIndex) =>
                                            this.renderTimelineEvent(combinedCampaign, combinedIndex, groupIndex)
                                        )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    /**
     * Get the first and last date from a list of campaigns
     * @param {*} campaigns List of campaigns in the campaignType
     */
    getDateRange = (campaigns) => {
        let dateOnline, dateOffline;

        try {
            const sortedCampaigns = campaigns.map((campaign) => campaign.dateOnline).sort((a, b) => Date.parse(a) - Date.parse(b));
            dateOnline = sortedCampaigns[0];
            dateOffline = sortedCampaigns.slice(-1)[0];
        } catch (error) {
            console.error('Error when sorting campaigns', error);
        }

        return { dateOnline, dateOffline };
    };

    /**
     * Renders the cells of each row and adds styling if needed
     * @param {*} cellCount The count of the horizontal cells
     * @param {*} cellWidth The width of each cell in %
     * @returns The cells to render
     */
    renderCells = (cellCount, cellWidth) => {
        const { startDate, timeframe } = this.props;
        const cells = [];

        const firstCellYear = (Math.round(moment.duration(moment(startDate).endOf('isoWeek').diff(startDate)).asDays()) / 7) * cellWidth;

        for (let i = 0; i < cellCount; i++) {
            let isToday = false,
                isWeekend = false;

            // Check if the date is today or this week
            if ((timeframe === 'week' || timeframe === 'month') && moment(startDate).add(i, 'days').isSame(Date.now(), 'day')) {
                isToday = true;
            } else if (timeframe === 'year' && moment(startDate).add(i, 'weeks').isSame(Date.now(), 'week')) {
                isToday = true;
            }
            // Check if the date is a weekend day
            if (
                (timeframe === 'week' || timeframe === 'month') &&
                (moment(startDate).add(i, 'days').day() === 0 || moment(startDate).add(i, 'days').day() === 6)
            ) {
                isWeekend = true;
            }

            cells.push(
                <div
                    className={`calendar-grid-rows__row__body__cells__cell ${isWeekend ? 'isWeekend' : ''} ${isToday ? 'isToday' : ''}`}
                    style={{
                        width: `${(timeframe === 'quarterly' || timeframe === 'year') && i === 0 ? firstCellYear : cellWidth}%`
                    }}
                    key={i}></div>
            );
        }

        return cells;
    };

    /**
     * Calculates the place and width of each timeline event and returns it
     * @param {*} campaign
     * @param {*} combinedIndex The index of the combined campaigns
     * @returns A rendered timeline event
     */
    renderTimelineEvent = (campaign, combinedIndex, groupIndex) => {
        const { startDate, endDate, onCampaignClick, showChannels, toggleShowChannels } = this.props;

        const range = Math.abs(moment(startDate).diff(moment(endDate), 'days')) + 1;
        const { startingPoint, endingPoint } = this.getCampaignCellRange(campaign, range);

        const leftPercentage = (startingPoint / range) * 100 + '%';
        const widthPercentage = ((endingPoint - startingPoint) / range) * 100 + '%';
        const width = `calc(${widthPercentage} - 4px)`;
        const campaignStartedBefore = moment(campaign.dateOnline).valueOf() < moment(startDate).valueOf();
        const campaignEndsLater = moment(campaign.dateOffline).valueOf() > moment(endDate).valueOf();

        // Check if the timeline event icon should be visible
        let showTimelineEventIcon = true;
        try {
            if (this.setup.campaigns.calendar.hideTimelineEventIcon) showTimelineEventIcon = false;
        } catch (error) {}

        return (
            <TimelineEvent
                key={`${campaign.id}${combinedIndex}`}
                groupIndex={groupIndex}
                campaign={campaign}
                style={{ width, left: leftPercentage }}
                onCampaignClick={onCampaignClick}
                campaignStartedBefore={campaignStartedBefore}
                campaignEndsLater={campaignEndsLater}
                showChannels={showChannels}
                toggleShowChannels={toggleShowChannels}
                showTimelineEventIcon={showTimelineEventIcon}
                collapseRows={this.collapseRows}
                width={((endingPoint - startingPoint) / range) * 100}
            />
        );
    };

    /**
     * Goes to the campaign
     * @param {*} campaign
     */
    onCampaignClick = (campaign) => {
        if (campaign.type !== 'featured_event') {
            this.props.onCampaignClick(campaign);
        }
    };

    /**
     * Collapses the rows a group of campaigns
     * @param {*} type
     */
    collapseRows = (type) => {
        const { collapseRows, compactView } = this.props;
        if (!compactView && collapseRows) collapseRows(type);
    };

    /**
     * Returns the horizontal values (starting and ending point) of a campaign within a calendar row
     * @param {*} campaign - The calendar campaign
     * @param {*} range - The timeframe range in days
     */
    getCampaignCellRange = (campaign, range) => {
        const { startDate, endDate } = this.props;
        let startingPoint, endingPoint;

        // Start of the campaign
        if (moment(campaign.dateOnline).valueOf() <= moment(startDate).valueOf()) {
            startingPoint = 0;
        } else {
            startingPoint = Math.abs(moment(startDate).diff(moment(campaign.dateOnline), 'days'));
        }

        // End of the campaign
        if (moment(campaign.dateOffline).valueOf() >= moment(endDate).valueOf()) {
            endingPoint = range;
        } else {
            endingPoint = Math.abs(moment(startDate).diff(moment(campaign.dateOffline), 'days')) + 1;
        }

        return {
            startingPoint,
            endingPoint
        };
    };
}

export default CalendarGridCells;
