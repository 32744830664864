import moment from 'moment';

/**
 * Gets a list of campaigns and combines those campaigns that have overlapping timeframes (so startDate and endDates)
 * @param {*} campaigns
 * @param {*} campaignType
 * @returns An array of those combined campaigns
 */
const getCombinedCampaigns = (campaigns, campaignType) => {
    const combinedCampaignsList = [];
    const pushedCampaigns = [];

    for (let i = 0; i < campaigns.length; i++) {
        const campaign = campaigns[i];

        // Return if the campaigns has invalid dates
        if (!moment(campaign.dateOnline).isValid() || !moment(campaign.dateOffline).isValid()) return;

        if (combinedCampaignsList.length === 0) {
            // Create the first object of combined campaigns
            if (!pushedCampaigns.includes(campaign.id)) {
                combinedCampaignsList.push({ campaigns: [campaign] });
                pushedCampaigns.push(campaign.id);
            }
        } else {
            for (let j = 0; j < combinedCampaignsList.length; j++) {
                const combinedCampaigns = combinedCampaignsList[j];

                if (!combinedCampaigns) return;

                // Calculate the startDate and endDate of this combined campaign list
                let startDate = combinedCampaigns.campaigns.map((campaign) => campaign.dateOnline).sort((a, b) => Date.parse(a) - Date.parse(b))[0];
                let endDate = combinedCampaigns.campaigns.map((campaign) => campaign.dateOffline).sort((a, b) => Date.parse(b) - Date.parse(a))[0];

                // Make sure that campaigns that start/end on the same day, but with a different time, will still be combined
                startDate = moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');
                endDate = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');

                // If the range of this campaign overlaps with an existing combinedList, add it to that one
                if (
                    moment(campaign.dateOnline).isBetween(moment(startDate), moment(endDate), undefined, '[]') ||
                    moment(campaign.dateOffline).isBetween(moment(startDate), moment(endDate), undefined, '[]') ||
                    (moment(campaign.dateOnline).isBefore(moment(startDate)) && moment(campaign.dateOffline).isAfter(moment(endDate)))
                ) {
                    // Extra check to prevent double campaigns
                    const existingIds = combinedCampaignsList[j].campaigns.map((c) => c.id);

                    if (!existingIds.includes(campaign.id)) {
                        if (!pushedCampaigns.includes(campaign.id)) {
                            combinedCampaignsList[j].campaigns.push(campaign);
                            pushedCampaigns.push(campaign.id);
                        }
                    }
                }
            }

            // If the campaign did not fit into an existing range, make a new range
            if (!pushedCampaigns.includes(campaign.id)) {
                combinedCampaignsList.push({ campaigns: [campaign] });
                pushedCampaigns.push(campaign.id);
            }
        }
    }

    // Add the dateOnline and dateOffline for each combined set of campaigns
    for (let i = 0; i < combinedCampaignsList.length; i++) {
        const combinedCampaigns = combinedCampaignsList[i];
        const dateOnline = combinedCampaigns.campaigns.map((campaign) => campaign.dateOnline).sort((a, b) => Date.parse(a) - Date.parse(b))[0];
        const dateOffline = combinedCampaigns.campaigns.map((campaign) => campaign.dateOffline).sort((a, b) => Date.parse(b) - Date.parse(a))[0];

        combinedCampaignsList[i].dateOnline = dateOnline;
        combinedCampaignsList[i].dateOffline = dateOffline;
        combinedCampaignsList[i].group = 'combined';
        combinedCampaignsList[i].category = campaignType;
    }

    return combinedCampaignsList;
};

export default getCombinedCampaigns;
